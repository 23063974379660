import React from "react";
import s from "./Arrows.module.scss";

export const SamplePrevArrow = (props) => {
    const { onClick } = props;
    return (
        <div className={s.arrowWrapper} onClick={onClick} data-style={props.dataStyle}>
            <div
                className={s.NextArrow}
            />
        </div>
    );
}

export const SampleNextArrow = (props) => {
    const { onClick } = props;
    return (
        <div className={s.arrowWrapper} onClick={onClick} data-style={props.dataStyle}>
            <div
                className={s.PrevArrow}
            />
        </div>
    );
}

const Arrows = {
    Next: SampleNextArrow,
    Prev: SamplePrevArrow
}

export default Arrows;