import store from '../store';
import { setFooterList } from "../store/actions/footer";
import { getRequest } from './base';

export class FooterAPI {
    static async getList() {
        const response = await getRequest({
            url: "/api/footer/",
        })
        store.dispatch(setFooterList(response))
        return response;
    }
}