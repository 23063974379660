import React, { useEffect, useState } from "react";
import { Switch, Router, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../store";

import { RenderRoutes } from "./routes";
import ScrollToTop from "./ScrollToTop";
import history from "../history";
import { cookieWatcher } from "../helpers";
import { UserProfileAPI } from "../api";

const Routes = () => {
  const [, setIsAthorized] = useState(false);
  // const [isUserHasToken, setIsUserHasToken] = useState(
  //   localStorage.getItem("token")
  // );

  useEffect(() => {
    cookieWatcher(({ oldValue, newValue }) => {
      if (newValue.includes("login=true")) {
        UserProfileAPI.getUserInfo()
          .then(() => {
            setIsAthorized(true);
          })
          .catch(() => {
            setIsAthorized(false);
          });
      } else if (newValue.includes("login=false")) {
        UserProfileAPI.getUserInfo()
          .then(() => {
            setIsAthorized(false);
          })
          .catch(() => {
            setIsAthorized(false);
          });
      }
    }, 1000);
  }, []);

  useEffect(() => {
    document.cookie = "first_load=true";
    UserProfileAPI.getUserInfo()
      .then(() => {
        setIsAthorized(true);
      })
      .catch(() => {
        setIsAthorized(false);
      });
  }, []);
  return (
    <Provider store={store}>
      <Router history={history}>
        <ScrollToTop>
          <Switch>
            <RenderRoutes />
            <Redirect to="/" />
          </Switch>
        </ScrollToTop>
      </Router>
    </Provider>
  );
};

export default Routes;
