import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { UserProfileAPI } from "../api/userProfile";
import { CHECK_EMAIL_PATH, HOME_PATH, LOGIN_PATH } from "../routes/constants";
import { logOut, resetPassword, recoverPassword, setLoginError } from "../store/actions/user";

const useAuthorization = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isAuthorized, loginError } = useSelector(({ user }) => user);

  const logIn = async ({ email, password }) => {
    UserProfileAPI.logIn({ email, password });
  };

  const resetLoginError = () => {
    dispatch(setLoginError(''));
  }

  const logOutHandler = () => {
    try {
      const token = localStorage.getItem("token");
      localStorage.clear();
      UserProfileAPI.logOut(token);
      dispatch(logOut());
      history.push(HOME_PATH);
    } catch (e) {
      // TODO add base error handler;
      console.warn("Something went wrong");
    } finally {
      localStorage.clear();
      dispatch(logOut());
      history.push(HOME_PATH);
    }
  };

  const reset = async ({ email }) => {
    try {
      await UserProfileAPI.reset({ email });
      dispatch(recoverPassword());
      localStorage.clear();
      history.push(CHECK_EMAIL_PATH);
    } catch (e) {
      // TODO add base error handler;
      console.warn("Something went wrong");
    }
  };

  const changePassword = async ({
    password,
    passwordConfirmation,
    uid,
    token,
    setErrors,
  }) => {
    try {
      await UserProfileAPI.changePassword({
        new_password1: password,
        new_password2: passwordConfirmation,
        uid,
        token,
      });
      dispatch(resetPassword());
      localStorage.clear();
      history.push(LOGIN_PATH);
    } catch (e) {
      setErrors({
        passwordConfirmation: e.data.new_password2.map((err) => err),
      });
    }
  };

  return {
    isAuthorized,
    logIn,
    logOut: logOutHandler,
    reset,
    changePassword,
    loginError,
    resetLoginError,
  };
};

export default useAuthorization;
