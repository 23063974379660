import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikErrorFocus from "formik-error-focus";

import s from "./LoginForm.module.scss";
import useAuthorization from "../../hooks/useAuthorization";
// components
import FormField from "../../components/FormField";

const validationSchema = Yup.object({
  email: Yup.string().required("Required"),
  password: Yup.string()
    .test(
      "password",
      "The password cannot start or end with a space.",
      (value) =>
        value?.split("")[0] !== " " &&
        value?.split("")[value.length - 1] !== " "
    )
    .required("Required"),
});

const Login = () => {
  const { logIn, loginError, resetLoginError } = useAuthorization();

  useEffect(() => {
    if(loginError) {
      setTimeout(() => resetLoginError(), 5000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginError]);

  return (
    <div className={s.Login}>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          logIn(values);
          setSubmitting(false);
        }}
      >
        <Form>
          <FormField label="Email address" name="email" type="email" required />
          <FormField
            label="Password"
            name="password"
            type="password"
            required
          />

          <div className={s.loginButtons}>
            <button className="btn" type="submit">
              Login
            </button>
            {loginError ? (
              <span className="error">
                {loginError}
              </span>
            ) : null}
            <FormikErrorFocus
              offset={-35}
              align={"top"}
              focusDelay={200}
              ease={"linear"}
              duration={500}
            />

            <Link className={s.forgottenPassword} to="/login/password-recovery">
              Forgotten password?
            </Link>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default Login;
