import React from "react";
import "./CardNumberElement.scss";

import {CardNumberElement} from '@stripe/react-stripe-js';

const DEFAULT_CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
        base: {
            fontFamily: "Roboto",
            fontSize: "16px",
            // lineHeight: "1.75",
            fontWeight: 300,
            fontSmoothing: 'antialiased',
            // height: '56px',
            lineHeight: '56px',
            // maxWidth: '238px',
            // width: '100%',
            ':-webkit-autofill': {
                color: '#ff6702'
            },
            '::placeholder': {
                color: 'transparent'
            },
        },
        invalid: {
            iconColor: '#ff6702',
            color: '#ff6702',
        },
    },
  };



const StripeCardNumber = ({onChange, errorMessage = "", stripe}) => {
    return (
        <div className={"formGroup"} id="cardNumberElement">
            <label>
                Card Number
            </label>
            {/* <Elements stripe={stripe}> */}
                <CardNumberElement 
                    options={DEFAULT_CARD_OPTIONS}
                    onChange={onChange}
                />
            {/* </Elements> */}
            {
                errorMessage && 
                <p className={"inputErrorMessage"}>
                    {errorMessage}
                </p>    
            }
            
        </div>
        
    )

}

export default StripeCardNumber;