import React from "react";

import s from "./Button.module.scss";

const Button = ({
  isOrange,
  isBorderless = false,
  children,
  onClickHandler,
}) => {
  return (
    <button
      className={`${isBorderless ? s.borderless : s.Button} ${
        isOrange ? s.orange : ""
      }`}
      onClick={onClickHandler}
    >
      {children}
    </button>
  );
};

export default Button;
