import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Calendar } from "../../assets/svg/calendar.svg";
import { ReactComponent as Doctor } from "../../assets/svg/doctor.svg";
import { ReactComponent as LooksGood } from "../../assets/svg/looks-good.svg";
import { ReactComponent as Attention } from "../../assets/svg/action-needed.svg";
import { ReactComponent as PrintLogo } from "../../assets/svg/logo-whiteout.svg";
import { useSelector } from "react-redux";
import { prettyDate, print } from "../../helpers";
import { TestsAPI, SubscriptionsAPI } from "../../api";
import {
  LatestResult,
  Layout,
  Banner,
  PageHeading,
  Loader,
} from "../../components";
import s from "./TestResults.module.scss";

const ageCategory = {
  eighteenForty: 18,
  fortyFortyNine: 40,
  fiftyFiftyNine: 50,
  overSixty: 60,
};
const testResults = {
  noTest: "noTest",
  high: "high",
  low: "low",
  inAYear: "inAYear",
};

const dateToday = new Date();
const oneYear = 31536000000;

const sortByDate = (a, b) => {
  const dateA = new Date(a?.dispatched);
  const dateB = new Date(b?.dispatched);
  return dateA - dateB;
};

const TestResults = () => {
  const { subscription: subscriptions } = useSelector(({ user }) => user);
  const { userTestsLoading, userSubcriptionLoading } = useSelector(
    ({ loaders }) => loaders
  );
  const userTests = useSelector(({ tests }) => tests.userTests.filter((test) => test.status !== 'Error').sort(sortByDate));

  const latestTest = useMemo(() => [...userTests].reverse()[0], [userTests]);
  const [isLoading, setIsLoading] = useState(false);
  const [symptomsByAge, setSymptomsByAge] = useState(ageCategory.eighteenForty);
  const [bannerStep, setBannerStep] = useState(true);
  const [testResult, setTestResult] = useState(testResults.noTest);

  useEffect(() => {
    setIsLoading(userTestsLoading || userSubcriptionLoading);
  }, [userTestsLoading, userSubcriptionLoading]);

  useEffect(() => {
    TestsAPI.getUserTests();
    SubscriptionsAPI.getUserSubscription();
  }, []);

  useEffect(() => {
    if (latestTest) {
      const { result, dispatched } = latestTest;
      const lastTestDate = +new Date(dispatched);
      const isYearPass = +dateToday - lastTestDate >= oneYear;
      if (dispatched) {
        if (isYearPass) {
          return setTestResult(testResults.inAYear);
        } else if (result < 10) {
          setTestResult(testResults.low);
        } else if (result >= 10) {
          setTestResult(testResults.high);
        }
      } else {
        setTestResult(testResults.noTest);
      }
    }
  }, [latestTest]);

  const renderTest = (test, index) => {
    if (test.status === 'Completed') {
      const isHigh = test.result === '> 10' || +test.result > 10;
      return (
        <div className={s.prevTestCard} key={index}>
          <div className={s.prevTestResult}>
            <div className={s.colorBox}>
              {!isHigh ? <LooksGood /> : <Attention />}
            </div>
            {!isHigh ? <h3>Looks Good</h3> : <h3>Action Needed</h3>}
          </div>

          <div className={s.prevTestInfo}>
            <div className={s.prevTestInfoItem}>
              QFIT: {!isHigh ? "LOW" : "HIGH"}
            </div>
            <div className={s.prevTestInfoItem}>
              <span>Date: </span>
              {prettyDate(test?.dispatched)}
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <Layout>
      {!isLoading ? (
        <div className={s.TestResults}>
          <PageHeading>
            {testResult === testResults.inAYear ? (
              <h2>It’s time to order another test!</h2>
            ) : (
              <h2>My test results</h2>
            )}
          </PageHeading>
          <div className={`container ${s.pageHeadingAlignment}`}>
            <LatestResult
              testResult={testResult}
              testResults={testResults}
              latestTest={latestTest}
            />
            {testResult === testResults.low && (
              <div className={s.questions}>
                <h3>To find out next steps, answer the following questions</h3>

                <h3>How old are you?</h3>
                <div className={s.ageButtons}>
                  <button
                    className={`${
                      symptomsByAge === ageCategory.eighteenForty
                        ? s.activeBtn
                        : s.ageButton
                    }`}
                    type="button"
                    onClick={() => setSymptomsByAge(ageCategory.eighteenForty)}
                  >
                    18 - 39 years
                  </button>
                  <button
                    className={`${
                      symptomsByAge === ageCategory.fortyFortyNine
                        ? s.activeBtn
                        : s.ageButton
                    }`}
                    type="button"
                    onClick={() => setSymptomsByAge(ageCategory.fortyFortyNine)}
                  >
                    40 - 49 years
                  </button>
                  <button
                    className={`${
                      symptomsByAge === ageCategory.fiftyFiftyNine
                        ? s.activeBtn
                        : s.ageButton
                    }`}
                    type="button"
                    onClick={() => setSymptomsByAge(ageCategory.fiftyFiftyNine)}
                  >
                    50 - 59 years
                  </button>
                  <button
                    className={`${
                      symptomsByAge === ageCategory.overSixty
                        ? s.activeBtn
                        : s.ageButton
                    }`}
                    type="button"
                    onClick={() => setSymptomsByAge(ageCategory.overSixty)}
                  >
                    60 years or above
                  </button>
                </div>

                <h3>Have you experienced the following symptoms?</h3>
                {ageCategory.eighteenForty === symptomsByAge && (
                  <div className={s.testExperience}>
                    Rectal bleeding PLUS abdominal pain / change in bowel habit
                    / weight loss
                  </div>
                )}
                {ageCategory.fortyFortyNine === symptomsByAge && (
                  <div className={s.testExperience}>
                    Weight loss AND abdominal pain OR rectal bleeding PLUS
                    abdominal pain/change in bowel habit/ weight loss
                  </div>
                )}
                {ageCategory.fiftyFiftyNine === symptomsByAge && (
                  <div className={s.testExperience}>
                    Rectal bleeding OR Weight loss and abdominal pain OR change
                    in bowel habit
                  </div>
                )}
                {ageCategory.overSixty === symptomsByAge && (
                  <div className={s.testExperience}>
                    Change in bowel habit OR Rectal bleeding OR weight loss
                    abdominal pain
                  </div>
                )}
                <div className={s.symptomsButtons}>
                  <button
                    type="button"
                    className={`btn ${!bannerStep ? "btn-grey" : "btn-orange"}`}
                    onClick={() => setBannerStep(true)}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className={`btn ${bannerStep ? "btn-grey" : "btn-orange"}`}
                    onClick={() => setBannerStep(false)}
                  >
                    No
                  </button>
                </div>
              </div>
            )}
          </div>
          {(testResult === testResults.low ||
            testResult === testResults.high) && (
            <div className={s.nextSteps}>
              <div className="container">
                <h3 className={s.nextStepsHeading}>Your next steps</h3>
              </div>

              <div className={`container ${s.nextStepsCard}`}>
                <div className={s.nextStepsInnerHeading}>
                  {!bannerStep ? (
                    <>
                      <h3>Take another QFIT Test in 12 months time</h3>
                      <div className={s.resultsDate}>
                        {prettyDate(
                          userTests?.[userTests?.length - 1]?.dispatched
                        )}
                      </div>
                    </>
                  ) : (
                    <h3>We recommend that you speak with your GP</h3>
                  )}
                  {!bannerStep ? <Calendar /> : <Doctor />}
                </div>

                <div className={s.nextStepsTextContent}>
                  {!bannerStep ? (
                    <>
                      <p>
                        Regular testing matters, so in 12 months time, come back
                        to order another kit, so you can send us another sample.
                      </p>
                      <p>
                        Don’t want to have to remember, setup an annual
                        <Link to="/"> subscription</Link>.
                      </p>
                    </>
                  ) : (
                    <>
                      <p>
                        We think you should speak to your GP to ask them to
                        investigate further, and you should take another test in
                        three months’ time. It may be nothing, but also it is
                        best to be safe, rather than sorry.
                      </p>
                      <p>
                        To help the GP in their investigations, please take a
                        print screen of your results and take it with you.
                      </p>
                      <button
                        className="btn btn-orange"
                        onClick={() => print("printarea", "ifmcontentstoprint")}
                      >
                        Print Screen
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          <iframe
            id="ifmcontentstoprint"
            style={{
              height: "0px",
              width: "0px",
              position: "absolute",
              border: "none",
            }}
            title="Matter Health"
          />
          <div className="container">
            <div className={s.prevTests}>
              <div className={s.prevTestHeading}>
                <h4>Previous tests</h4>
                {subscriptions?.subscriptions.length === 0 ? (
                  <span>No subscription active</span>
                ) : (
                  <span>Subscribed</span>
                )}
              </div>
              {!userTests.some(test => test.status === 'Completed') && (
                <div className={s.noTests}>
                  <h4>Looks like you’ve not had any previous tests</h4>
                  <p>
                    Once you’ve had multiple tests with Matter Health, your
                    results will show here so you can look back at them at any
                    time.
                  </p>
                </div>
              )}

              {/* {(testResult === testResults.low ||
                testResult === testResults.high ||
                testResult === testResults.inAYear) && ( */}
                <>
                  <div className={s.prevTestCards} id="printarea">
                    <div className={s.printLogo}>
                      <PrintLogo style={{ paddingBottom: "25px" }} />
                    </div>
                    {userTests
                      .map((test, index) => renderTest(test, index))
                      .reverse()}
                  </div>
                  <Link className="btn btn-orange" to="/get-test">
                    Order a test
                  </Link>
                </>
              {/* )} */}
            </div>

            <Banner />
          </div>
        </div>
      ) : (
        Loader.Small
      )}
    </Layout>
  );
};

export default TestResults;
