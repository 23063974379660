import s from "./NumbersSlider.module.scss";
import React from "react";
import useMedia from "../../../hooks/useMedia";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";
import Arrows from "../Arrows";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  nextArrow: <Arrows.Next />,

  prevArrow: <Arrows.Prev />,
  responsive: [
    {
      breakpoint: 990,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 691,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

const NumbersSlider = () => {
  let media = useMedia("(max-width: 990px) ");

  if (media) {
    return (
      <div className={s.trustedWorldwideBigNumbers}>
        <Slider {...settings}>
          <div className={s.trustedWorldwideBigItem}>
            <div>42,000</div>
            <p>people are diagnosed with bowel cancer every year in the UK</p>
          </div>
          <div className={s.trustedWorldwideBigItem}>
            <div>15</div>
            <p>minutes every day, somebody is diagnosed with bowel cancer</p>
          </div>
          <div className={s.trustedWorldwideBigItem}>
            <div>16,000</div>
            <p>people die of bowel cancer in the UK every year</p>
          </div>
          <div className={s.trustedWorldwideBigItem}>
            <div>2nd</div>
            <p>biggest killer - because it's harder to spot</p>
          </div>
        </Slider>
      </div>
    );
  }
  return (
    <div className={s.trustedWorldwideBigNumbers}>
      <div className={s.trustedWorldwideBigItem}>
        <div>42,000</div>
        <p>people are diagnosed with bowel cancer every year in the UK</p>
      </div>
      <div className={s.trustedWorldwideBigItem}>
        <div>15</div>
        <p>minutes every day, somebody is diagnosed with bowel cancer</p>
      </div>
      <div className={s.trustedWorldwideBigItem}>
        <div>16,000</div>
        <p>people die of bowel cancer in the UK every year</p>
      </div>
      <div className={s.trustedWorldwideBigItem}>
        <div>2nd</div>
        <p>biggest killer - because it's harder to spot</p>
      </div>
    </div>
  );
};

export default NumbersSlider;
