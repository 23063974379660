import { http } from "./interceptor";

const request = async ({method, url, data, headers}) => {
    const response = await http({
        method,
        headers,
        url,
        data
    });
    return response.data;
};

export const getRequest = ({url, data, headers}) => request({url, data, headers, method: "GET"});
export const postRequest = ({url, data, headers}) => request({url, data, headers, method: "POST"});
export const deleteRequest = ({url, data, headers}) => request({url, data, headers, method: "DELETE"});
export const putRequest = ({url, data, headers}) => request({url, data, headers, method: "PUT"});


