import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { UserProfileAPI } from "../../api/userProfile";
import { authorization } from "../../store/actions/user";

export function useGetTestForm(formData) {
  const history = useHistory();
  const dispatch = useDispatch();
  const initialValues = {
    firstName: "",
    lastName: "",
    phone_number: "",
    password: "",
    confirmPassword: "",
    addressLineOne: "",
    addressLineSecond: "",
    city: "",
    country: "",
    postcode: "",
    email: "",
  };

  const onSubmit = async (formData, setErrors) => {
    const requestBody = {
      user: {
        email: formData.email,
        first_name: formData.firstName,
        last_name: formData.lastName,
        password: formData.password,
      },
      phone_number: formData.phone_number,
      billing_info: {
        country: formData.country,
        zip_code: formData.postcode,
        city: formData.city,
        address_line_first: formData.addressLineOne,
        address_line_second: formData.addressLineSecond,
      },
    };

    try {
      const response = await UserProfileAPI.registration(requestBody);
      dispatch(authorization(response.key));
      localStorage.setItem("token", response.key);
      history.push("/get-test/confirm/");
    } catch (e) {
      setErrors(
        Object.fromEntries(
          Object.entries({ ...e.data }).map(([key, value]) => [key, value])
        )
      );
    }
  };

  return {
    initialValues,
    onSubmit,
  };
}
