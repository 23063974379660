import React from "react";
import { Link } from "react-router-dom";

import s from "./Logo.module.scss";

const Logo = ({ isHeaderLogo = true }) => {
  return (
    <Link to="/" className={`${s.logo} ${!isHeaderLogo ? s.logoFooter : ""}`}>
      <span>Matter</span>
      <span>Health</span>
    </Link>
  );
};

export default Logo;
