import {GET_SUBSCRIPTIONS, LOG_OUT} from '../../constants';

const initialState = {
    count: 0,
    next: null,
    previous: null,
    subscriptions: []
};

const subscriptionsReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case GET_SUBSCRIPTIONS:
            return {
                ...state,
                count: payload.count,
                next: payload,
                previous: payload,
                subscriptions: [...payload.results]
            }    
        case LOG_OUT: 
            return initialState;
        default:
            return state;
    }
}

export default subscriptionsReducer;