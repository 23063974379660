import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import useGetTest from './useGetTest'

import s from "./GetTest.module.scss";

// components
import Layout from "../../components/Layout";
import PageHeading from "../../components/PageHeading";
import GetTestForm from "../../forms/get-test-form";
import ConfirmTestForm from "../../forms/confirm-test";
import useAuthorization from "../../hooks/useAuthorization";
import GetTestFormWithAuth from "../../forms/get-test-form-auth/GetTestFormAuth";
import { SubscriptionsAPI } from "../../api/subscriptions";
import { useSelector } from "react-redux";

const GetTest = () => {
  const {
    isAuthorized,
  } = useAuthorization();
  const location = useLocation();
  const {
    subscriptionPlan,
    oneTypePayment,
    activeTestType,
    changeTestType
  } = useGetTest();

  useEffect(() => {
    isAuthorized && SubscriptionsAPI.getUserSubscription()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout withGeometry>
      <div className={s.GetTest}>
        <PageHeading>
          <h2>Get a Test</h2>
          <p>Usual delivery estimate - 2 days from order</p>
        </PageHeading>

        <div className="container">
          <div className={s.testSteps}>
            <div
              className={`${s.testStep} ${location.pathname === "/get-test" ? s.activeTestPage : ""
                }`}
            >
              Account details
            </div>
            <div
              className={`${s.testStep} ${location.pathname.match("get-test/confirm")
                  ? s.activeTestPage
                  : ""
                }`}
            >
              Confirm and pay
            </div>
          </div>
        </div>

        <Switch>
          <Route path="/get-test" exact>
            <div className={s.testType}>
              <div className="container">
                <h4>Test Type</h4>
                <div className={s.testTypesCards}>
                  {
                    oneTypePayment &&
                    <div className={`${s.testTypesCard} ${activeTestType === "oneTimePayment" && s.activeTestType}`} onClick={() => changeTestType("oneTimePayment")}>
                      <div className={s.cardText}>
                        <h4>{oneTypePayment.name}</h4>
                        <p>{oneTypePayment.short_description}</p>
                      </div>

                      <div className={s.cardPrice}>
                        <h2>£{Math.trunc(oneTypePayment.cost)}</h2>
                      </div>
                    </div>
                  }
                  {
                    subscriptionPlan &&
                    <div className={`${s.testTypesCard} ${activeTestType === "subscription" && s.activeTestType}`} onClick={() => changeTestType("subscription")}>
                      <div className={s.cardText}>
                        <h4>{subscriptionPlan.name}</h4>
                        <p>{subscriptionPlan.short_description}</p>
                      </div>

                      <div className={s.cardPrice}>
                        <h2>£{Math.trunc(subscriptionPlan.cost)}</h2>
                        <span>{subscriptionPlan.description}</span>
                      </div>
                    </div>
                  }
                </div>

                <h4>
                  Take away the hassle of having to remember a test every year!
                </h4>
                <p>
                  For the same cost as a one off test each year (or two coffee's
                  each month), save the worry of remembering and receive a test
                  in kit every year.
                </p>
              </div>
            </div>
            {isAuthorized ? <GetTestFormWithAuth /> : (
              <div className={s.accountDetails}>
                <div className="container">
                  <h4>Account details</h4>
                </div>
                <GetTestForm />
              </div>
            )}

          </Route>

          <Route path="/get-test/confirm">
            <div className={s.testConfirm}>
              <div className="container">
                <h4>Confirm and pay</h4>
              </div>

              <ConfirmTestForm />
            </div>
          </Route>
        </Switch>
      </div>
    </Layout>
  );
};

export default GetTest;
