import {
    AUTHORIZATION,
    LOG_OUT,
    RESET_PASSWORD,
    RECOVER_PASSWORD,
    SAVE_USER_INFO,
    SAVE_USER_CARD,
    SAVE_USER_STRIPE_SUBSCRIPTION,
    LOGIN_ERROR
} from "../constants"

export const authorization = (token) => {
    return {
        type: AUTHORIZATION,
        payload: token
    }
}

export const saveUserInfo = (userInfo) => {
    return {
        type: SAVE_USER_INFO,
        payload: userInfo
    }
}

export const saveUserCard = (card) => {
    return {
        type: SAVE_USER_CARD,
        payload: card
    }
}

export const saveUserStripeSubscription = (subscription) => {
    return {
        type: SAVE_USER_STRIPE_SUBSCRIPTION,
        payload: subscription
    }
}

export const logOut = () => {
    return {
        type: LOG_OUT
    }
}

export const recoverPassword = () => {
    return {
        type: RECOVER_PASSWORD
    }
}

export const resetPassword = () => {
    return {
        type: RESET_PASSWORD
    }
}

export const setLoginError = (payload) => {
    return {
        type: LOGIN_ERROR,
        payload,
    }
}
