import {
    GET_SUBSCRIPTIONS,
    GET_USER_SUBSCRIPTION
} from "../constants";

export const getSubscriptions = (subscriptions) => {
    return {
        type: GET_SUBSCRIPTIONS,
        payload: subscriptions
    }
}

export const getUserSubscription = (subscriptions) => {
    return {
        type: GET_USER_SUBSCRIPTION,
        payload: subscriptions
    }
}