import React, {useState, useEffect} from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import s from "./AccountDetailsForm.module.scss";

// components
import Button from "../../components/Button";
import FormField from "../../components/FormField";
import { UserProfileAPI } from "../../api/userProfile";
import FormikErrorFocus from "formik-error-focus";

const validationSchema = Yup.object({
  first_name: Yup.string()
    .required("Required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
    .max(149, "Maximum 150 letters."),
  last_name: Yup.string()
    .required("Required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
    .max(149, "Maximum 150 letters."),
  phone_number: Yup.string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .min(12, "Phone number is not valid")
    .max(12, "Phone number is not valid"),
  address_line_first: Yup.string()
    .required("Required")
    .matches(
      /^[A-Za-z0-9][A-Za-z0-9 ]*[A-Za-z0-9]*$/,
      "Only letters and numbers are allowed"
    )
    .max(50, "Maximum 50 letters."),
  address_line_second: Yup.string()
    .required("Required")
    .matches(
      /^[A-Za-z0-9][A-Za-z0-9 ]*[A-Za-z0-9]*$/,
      "Only letters and numbers are allowed"
    )
    .max(50, "Maximum 50 letters."),
  city: Yup.string()
    .required("Required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
    .max(100, "Maximum 100 letters."),
  country: Yup.string()
    .required("Required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
    .max(50, "Maximum 50 letters."),
  zip_code: Yup.string()
    .required("Required")
    .matches(
      /^[A-Za-z0-9][A-Za-z0-9 ]*[A-Za-z0-9]*$/,
      "Only letters and numbers are allowed"
    )
    .max(50, "Maximum 50 letters."),
});

const AccountDetailsForm = ({ userProfile }) => {
  const [isSuccess, setSuccess] = useState('');
  const onSubmit = async (requestBody, id) => {
    const data = {
      user: {
        first_name: requestBody.first_name,
        last_name: requestBody.last_name,
      },
      phone_number: requestBody.phone_number,
      parent_user_token: localStorage.getItem("token"),
      billing_info: {
        address_line_first: requestBody.address_line_first,
        address_line_second: requestBody.address_line_second,
        city: requestBody.city,
        country: requestBody.country,
        zip_code: requestBody.zip_code,
      },
    };
    await UserProfileAPI.updateUserInfo(id, data);
    setSuccess(true);
  };

  useEffect(() => {
    if(isSuccess) {
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    }
  }, [isSuccess]);

  return (
    <div className={s.AccountDetailsForm}>
      <div className="container">
        <h3>Your Details</h3>
        <Formik
          initialValues={{
            first_name: userProfile?.user?.first_name || "",
            last_name: userProfile?.user?.last_name || "",
            phone_number: userProfile?.phone_number || "",
            address_line_first:
              userProfile?.billing_info?.address_line_first || "",
            address_line_second:
              userProfile?.billing_info?.address_line_second || "",
            city: userProfile?.billing_info?.city || "",
            country: userProfile?.billing_info?.country || "",
            zip_code: userProfile?.billing_info?.zip_code || "",
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              {
                ...values,
                phone_number: values?.phone_number
                  ?.replace(/\)/g, "")
                  .replace(/\(/g, "")
                  .replace(/-/g, "")
                  .replace(/ /g, ""),
              },
              userProfile?.id
            );
            setSubmitting(false);
          }}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <FormField label="First Name" name="first_name" type="text" required />
              <FormField label="Last Name" name="last_name" type="text" required />
              <FormField
                onChange={setFieldValue}
                value={values?.phone_number}
                mobile
                label="Mobile Number"
                name="phone_number"
                type="text"
              />

              <p className={s.formTextInfo}>
                We only use your number to text you about your results and won't
                share it with anyone else.
              </p>

              <h3>Billing Address</h3>

              <FormField
                label="Address Line 1"
                name="address_line_first"
                type="text"
                required
              />
              <FormField
                label="Address Line 2"
                name="address_line_second"
                required
                type="text"
              />
              <FormField label="City" name="city" type="text" required />
              <FormField label="Country" name="country" type="text" required />
              <FormField label="Postcode" name="zip_code" type="text" required />

              <Button isOrange type="submit">
                Update
              </Button>
              <FormikErrorFocus
                offset={-35}
                align={"top"}
                focusDelay={200}
                ease={"linear"}
                duration={500}
              />
              {isSuccess ? (
                <p className={s.formTextInfo}>
                  Profile details was successfully changed!
                </p>
              ) : null}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AccountDetailsForm;
