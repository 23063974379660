import React, { useEffect } from "react";

import styles from "./styles.module.scss";

const Toast = ({ message, clearMessage }) => {
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        clearMessage(null);
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return (
    <div className={[styles.message, message ? styles.active : ""].join(" ")}>
      {message}
    </div>
  );
};

export default Toast;
