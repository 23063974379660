import React from "react";
import { ReactComponent as LooksGood } from "../../assets/svg/looks-good.svg";
import { ReactComponent as Clock } from "../../assets/svg/results-on-there-way-symbol.svg";
import { ReactComponent as Attention } from "../../assets/svg/action-needed.svg";
import {prettyDate, addWorkDays} from "../../helpers";

import s from "./LatestResult.module.scss";
import {Link} from "react-router-dom";


const LatestResult = ({testResult, testResults, latestTest}) => {
    return (
        <>
            {testResult === testResults.noTest && (
                <>
                    <div className={s.headerDateWrapper}>
                        <h4 className={s.subtitle}>Latest test results</h4>
                        <div className={s.resultsDate}>Estimated delivery: {prettyDate(addWorkDays(latestTest?.created_at, 2))}</div>
                    </div>
                    <div className={s.resultContainer}>
                        <div className={s.result}>
                            <div className={s.info}>
                                <h4>YOU HAVE NO TEST RESULTS YET</h4>
                            </div>
                            <div className={s.colorBox}>
                                <Clock />
                            </div>
                        </div>
                        <p className={s.resultText}>
                            Once we have recieved your sample, we will notifty you on email when your results are ready and they will display here.
                        </p>
                    </div>
                </>
            )}
            {testResult === testResults.high && (
                <>
                    <div className={s.headerDateWrapper}>
                        <h4 className={s.subtitle}>Latest test results</h4>
                        <div className={s.resultsDate}>{prettyDate(latestTest.dispatched)}</div>
                    </div>
                    <div className={s.resultContainer}>
                        <div className={s.result}>
                            <div className={s.info}>
                                <h4>ACTION
                                    <br/>
                                    NEEDED
                                </h4>
                                <div className={s.qfit}>
                                    QFIT: <span>{+latestTest.result < 10 ? 'LOW' : 'HIGH'}</span>
                                </div>
                            </div>
                            <div className={s.colorBox}>
                                <Attention />
                            </div>
                        </div>
                        <p className={s.resultText}>
                            The results of the sample you sent us confirm a significant level
                            of blood present. While this does not prove there is a serious issue,
                            we would suggest you take another test in three months’ time, and 
                            also contact your GP immediately to ask  for further investigation.
                        </p>
                    </div>
                </>
            )}
            {testResult === testResults.low && (
                <>
                    <div className={s.headerDateWrapper}>
                        <h4 className={s.subtitle}>Latest test results</h4>
                        <div className={s.resultsDate}>{prettyDate(latestTest.dispatched)}</div>
                    </div>
                    <div className={s.resultContainer}>
                        <div className={s.result}>
                            <div className={s.info}>
                                <h4>LOW BLOOD CONTENT</h4>
                                <div className={s.qfit}>
                                    QFIT: <span>{+latestTest.result < 10 ? 'LOW' : 'HIGH'}</span>
                                </div>
                            </div>
                            <div className={s.colorBox}>
                                <LooksGood />
                            </div>
                        </div>
                        <p className={s.resultText}>
                            Your test confirms that the level of blood in<br/> your sample is below
                            the threshold.
                        </p>
                    </div>
                </>
            )}
            {testResult === testResults.inAYear && (
                <>
                    <div className={s.headerDateWrapper}>
                        <h4 className={s.subtitle}>It’s been a year already?</h4>
                    </div>
                    <div className={s.resultContainer}>
                        <div className={s.resultBanner}>
                            <h4>Your last QFIT test
                                <br/>
                                was {prettyDate(latestTest.dispatched)}</h4>

                        </div>
                        <p className={s.resultTextBanner}>
                            Keeping on top of your health matters which is why we recommend a test every 12 months. Order your next test now
                            <Link className="btn btn-orange" to="/get-test">
                                Order a test
                            </Link>
                        </p>
                    </div>
                    <div className={s.headerDateWrapper}>
                        <h4 className={s.subtitle}>Latest test results</h4>
                        <div className={s.resultsDate}>{prettyDate(latestTest.dispatched)}</div>
                    </div>
                    <div className={s.resultContainer}>
                        <div className={s.result}>
                            <div className={s.info}>
                                <h4>LOW BLOOD CONTENT</h4>
                                <div className={s.qfit}>
                                    QFIT: <span>{+latestTest.result < 10 ? 'HIGH' : 'LOW'}</span>
                                </div>
                            </div>
                            <div className={s.colorBox}>
                                <LooksGood />
                            </div>
                        </div>
                        <p className={s.resultText}>
                            Your test confirms that the level of blood in<br/> your sample is below
                            the threshold.
                        </p>
                    </div>
                </>
            )}
        </>
            )
};

export default LatestResult;
