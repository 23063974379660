import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk";
import reducer from "./reducers";

let middlewares = [thunk];
if (process.env.NODE_ENV === "development") {
  const { createLogger } = require("redux-logger");
  const logger = createLogger({
    collapsed: true
  })
 
  middlewares.push(logger);
}

const store = compose(applyMiddleware(...middlewares))(createStore)(reducer);

export default store;