import { SET_FOOTER_LIST } from '../../constants';

const initialState = {
    list: [],
};

const footerReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case SET_FOOTER_LIST:
            return {
                list: [...payload.results]
            }
        default:
            return state;
    }
}

export default footerReducer;