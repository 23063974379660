import React from "react";

// Icons
import { ReactComponent as UserIcon } from "../../assets/svg/per-person.svg";
import { ReactComponent as TestInPostIcon } from "../../assets/svg/test-in-post.svg";
import { ReactComponent as BoxArrowRightIcon } from "../../assets/svg/box-arrow-right.svg";
import { ReactComponent as ReturnParcelIcon } from "../../assets/svg/return-parcel.svg";
import { ReactComponent as GetNotifiedIcon } from "../../assets/svg/get-notified.svg";
import packagingSmall from "../../assets/images/packaging-desktop@3x.jpg";
import packagingMedium from "../../assets/images/packaging-desktop@2x.jpg";
import packagingLarge from "../../assets/images/packaging-desktop.jpg";
import s from "./Steps.module.scss";

const Steps = ({ withoutSignUp = false }) => {
  return (
    <div className={s.StepsContainer} data-style={withoutSignUp}>
      <div className="Steps">
        {withoutSignUp ? null : (
          <div className={s.Step}>
            <UserIcon width={48} height={48} />
            <p>Sign up and order a test for £25 per person</p>
          </div>
        )}

        <div className={s.Step}>
          <TestInPostIcon />
          <p>Receive the test in the post, discretely labelled</p>
        </div>

        <div className={s.Step}>
          <BoxArrowRightIcon />
          <p>Collect the sample following the instructions included</p>
        </div>

        <div className={s.Step}>
          <ReturnParcelIcon />
          <p>Return the test in a prepaid package</p>
        </div>

        <div className={s.Step}>
          <GetNotifiedIcon />
          <p>Get notified of your results within 2 working days</p>
        </div>
      </div>

      <div className={s.packagingImg}>
        <img
          src={packagingLarge}
          srcSet={`${packagingLarge}, ${packagingMedium} 2x, ${packagingSmall} 3x`}
          // srcSet="images/our-story.png, images/our-story@2x.png 1126w, images/our-story@3x.png 1689w"
          // sizes="(max-width: 992px) 1  30vw, (min-width: 992px) 40vw, 100vw"
          alt="packaging"
        />
      </div>
    </div>
  );
};

export default Steps;
