import React from "react";
import "./CardCvcElement.scss";

import {CardCvcElement} from '@stripe/react-stripe-js';

const DEFAULT_CVC_OPTIONS = {
    style: {
        base: {
            fontFamily: "Roboto",
            fontSize: "16px",
            fontWeight: 300,
            fontSmoothing: 'antialiased',
            lineHeight: '56px',
            ':-webkit-autofill': {
                color: '#ff6702'
            },
            '::placeholder': {
                color: 'transparent'
            },
        },
        invalid: {
            // iconColor: '#ffc7ee',
            color: '#ffc7ee',
        },
    },
  };



const StripeCardCvc = ({onChange, errorMessage = ""}) => {
    return (
        <div className={"formGroup"} id="StripeCVCElement">
            <div>
                <label>
                    Security Code (CVC)
                </label>
                    <CardCvcElement 
                        className={"Stripe_CVC"}
                        options={DEFAULT_CVC_OPTIONS}
                        onChange={onChange}
                    />
                {
                    errorMessage && 
                    <p className={"inputErrorMessage"}>
                        {errorMessage}
                    </p>    
                }
            </div>
                <div className={'cvcCaption'}>
                    <p>
                        Your CVC security code is the final three digits printed on the signature strip of you debit/credit card.
                    </p>
                </div>
        </div>
        
    )

}

export default StripeCardCvc;