import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import { Link } from "react-router-dom";

// assets
import { ReactComponent as MinusIcon } from "../../assets/svg/faq-minus.svg";
import { ReactComponent as PlusIcon } from "../../assets/svg/faq-plus.svg";
import useAuthorization from "../../hooks/useAuthorization";

import s from "./Accordion.module.scss";

const FaqAccordion = () => {
  const { isAuthorized } = useAuthorization();
  return (
    <div className={s.accordion}>
      <Accordion allowZeroExpanded preExpanded={["a"]}>
        <AccordionItem uuid="a" className={s.accordionItem}>
          <AccordionItemHeading className={s.accordionHeading}>
            <AccordionItemState>
              {({ expanded }) => (
                <AccordionItemButton className={s.accordionButton}>
                  <span>Why is Matter Health here?</span>
                  {expanded ? <MinusIcon /> : <PlusIcon />}
                </AccordionItemButton>
              )}
            </AccordionItemState>
          </AccordionItemHeading>
          <AccordionItemPanel className={s.accordionPanel}>
            <p>
              We (that’s Ben & Mark!) lost a friend to bowel cancer and wanted
              to make it as easy for anyone to get a high quality bowel health
              check using the latest tests, processed by the best labs in the
              UK, and at as low cost as possible. We also wanted to make it easy
              to take a test every year and set up a subscription functionality
              so that every year a test comes through your letterbox, so there
              is no need to remind yourself every year - we’ll do all that to
              make it as simple as possible to take a regular test. You’ll also
              be able to track your results over time through your Matter Health
              account.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className={s.accordionItem}>
          <AccordionItemState>
            {({ expanded }) => (
              <AccordionItemHeading className={s.accordionHeading}>
                <AccordionItemButton className={s.accordionButton}>
                  <span>What if I get a high test score?</span>
                  {expanded ? <MinusIcon /> : <PlusIcon />}
                </AccordionItemButton>
              </AccordionItemHeading>
            )}
          </AccordionItemState>
          <AccordionItemPanel className={s.accordionPanel}>
            <p>
              A high test score can be for a number of reasons, many of them not
              serious.
              <br />
              <br />
              If you receive a high test score we recommend that you take
              another test in 3 months time. If the second test also returns a
              high score, we would strongly advise that you make an appointment
              with your GP to discuss getting a colonoscopy to see if you have
              anything concerning that might be causing the elevated fecal blood
              levels. Make sure you take your test results with you to your GP
              appointment! You can do this by taking a screenshot or going to
              your test results page and selecting <strong>PRINT SCREEN</strong>
              .
              <br />
              <br />
              Of course, if you have any concerns immediately go visit your GP
              and get some advice.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className={s.accordionItem}>
          <AccordionItemHeading className={s.accordionHeading}>
            <AccordionItemState>
              {({ expanded }) => (
                <AccordionItemButton className={s.accordionButton}>
                  <span>Am I too young to get a test?</span>
                  {expanded ? <MinusIcon /> : <PlusIcon />}
                </AccordionItemButton>
              )}
            </AccordionItemState>
          </AccordionItemHeading>
          <AccordionItemPanel className={s.accordionPanel}>
            <p>
              Unfortunately bowel cancer can affect anyone of any age. More than
              2,500 new cases are diagnosed each year in people under the age of
              50.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className={s.accordionItem}>
          <AccordionItemHeading className={s.accordionHeading}>
            <AccordionItemState>
              {({ expanded }) => (
                <AccordionItemButton className={s.accordionButton}>
                  <span>I haven’t received any emails, what should I do?</span>
                  {expanded ? <MinusIcon /> : <PlusIcon />}
                </AccordionItemButton>
              )}
            </AccordionItemState>
          </AccordionItemHeading>
          <AccordionItemPanel className={s.accordionPanel}>
            <p>
              Please check your junk folder in your inbox and mark us as a safe
              sender if your emails are automatically going into this folder.
              <br />
              If nothing has been received at all contact us at&nbsp;
              <Link
                to="#"
                onClick={(e) => {
                  window.location = "mailto:help@matter-health.com";
                  e.preventDefault();
                }}
              >
                help@matter-health.com
              </Link>
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className={s.accordionItem}>
          <AccordionItemHeading className={s.accordionHeading}>
            <AccordionItemState>
              {({ expanded }) => (
                <AccordionItemButton className={s.accordionButton}>
                  <span>How do I cancel my subscription?</span>
                  {expanded ? <MinusIcon /> : <PlusIcon />}
                </AccordionItemButton>
              )}
            </AccordionItemState>
          </AccordionItemHeading>
          <AccordionItemPanel className={s.accordionPanel}>
            <p>
              You can cancel your subscription through the{" "}
              <Link to={isAuthorized ? "/account" : "/get-test"}>
                My Account
              </Link>{" "}
              space.
              <br />
              <br />
              If we’re too late to stop a test which is already on it’s way to
              you this will be your last one. Remember that once you’ve
              cancelled no more tests will be delivered.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className={s.accordionItem}>
          <AccordionItemHeading className={s.accordionHeading}>
            <AccordionItemState>
              {({ expanded }) => (
                <AccordionItemButton className={s.accordionButton}>
                  <span>How do I change my payment details?</span>
                  {expanded ? <MinusIcon /> : <PlusIcon />}
                </AccordionItemButton>
              )}
            </AccordionItemState>
          </AccordionItemHeading>
          <AccordionItemPanel className={s.accordionPanel}>
            <p>
              Update your payment details in the{" "}
              <Link to={isAuthorized ? "/account" : "/get-test"}>
                My Account
              </Link>{" "}
              space.
              <br />
              <br />
              Once you’re in your account you can go to BILLING AND PAYMENTS,
              change your details and select UPDATE.
              <br />
              <br />
              If we’re too late to stop a payment due, your details will be
              updated from the next due payment.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className={s.accordionItem}>
          <AccordionItemHeading className={s.accordionHeading}>
            <AccordionItemState>
              {({ expanded }) => (
                <AccordionItemButton className={s.accordionButton}>
                  <span>How do I change my delivery address?</span>
                  {expanded ? <MinusIcon /> : <PlusIcon />}
                </AccordionItemButton>
              )}
            </AccordionItemState>
          </AccordionItemHeading>
          <AccordionItemPanel className={s.accordionPanel}>
            <p>
              Update your delivery address in the{" "}
              <Link to={isAuthorized ? "/account/details" : "/get-test"}>
                My Account
              </Link>{" "}
              space.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className={s.accordionItem}>
          <AccordionItemHeading className={s.accordionHeading}>
            <AccordionItemState>
              {({ expanded }) => (
                <AccordionItemButton className={s.accordionButton}>
                  <span>How can I update my password?</span>
                  {expanded ? <MinusIcon /> : <PlusIcon />}
                </AccordionItemButton>
              )}
            </AccordionItemState>
          </AccordionItemHeading>
          <AccordionItemPanel className={s.accordionPanel}>
            <p>
              You can update your password in the{" "}
              <Link
                to={isAuthorized ? "/account/change-password" : "/get-test"}
              >
                My Account
              </Link>{" "}
              space. Once you’re in your account you can go to ACCOUNT DETAILS,
              change your details and select UPDATE.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className={s.accordionItem}>
          <AccordionItemHeading className={s.accordionHeading}>
            <AccordionItemState>
              {({ expanded }) => (
                <AccordionItemButton className={s.accordionButton}>
                  <span>My test hasn’t arrived!</span>
                  {expanded ? <MinusIcon /> : <PlusIcon />}
                </AccordionItemButton>
              )}
            </AccordionItemState>
          </AccordionItemHeading>
          <AccordionItemPanel className={s.accordionPanel}>
            <p>
              Please drop us an email to&nbsp;
              <Link
                to="#"
                onClick={(e) => {
                  window.location = "mailto:help@matter-health.com";
                  e.preventDefault();
                }}
              >
                help@matter-health.com
              </Link>
            </p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default FaqAccordion;
