import React from "react";
import { Redirect, Route } from "react-router";

const PrivateRoute = ({ component: Component, isPrivate, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return localStorage.getItem("token") ? (
          <Component {...props} />
        ) : (
          <Redirect to={"/"} />
        );
      }}
    />
  );
};

export default PrivateRoute;
