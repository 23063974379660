import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import {
  useStripe,
  useElements,
  Elements,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { PaymentsAPI } from "../../api/payments";

import s from "./ConfirmTestForm.module.scss";

// components
import StripeCardCvc from "../../components/StripeElements/CardCvcElement";
import StripeCardNumber from "../../components/StripeElements/CardNumberElement";
import StripeExpiryDate from "../../components/StripeElements/CardExpiryElement";
import { useHistory } from "react-router";
import { ReactComponent as CloseMobileMenuIcon } from "../../assets/svg/mobile-menu-exit-button.svg";
import { UserProfileAPI } from "../../api/userProfile";

const stripePromise = loadStripe(
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    : "pk_test_LgP2cHtHNrujtJOca1paFY59002asAcpqD"
);
const ConfirmTestForm = () => {
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const [errors, setErrors] = useState({});
  const onChange = (value) => {
    setErrors({
      ...errors,
      [value.elementType]: value.error,
    });
  };
  const [updateCardError, setUpdateCardError] = useState(null);
  const [showChangeForm, setShowChangeForm] = useState(false);
  const [prevCard, setPrevCard] = useState({});
  const { activeTestType, oneTimePayedTest } = useSelector(
    ({ tests }) => tests
  );
  const { subscriptions } = useSelector(({ subscriptions }) => subscriptions);
  const [userInfo, setUserInfo] = useState({});
  const [paymentId, setPaymentId] = useState(null);

  useEffect(() => {
    PaymentsAPI.getPaymentCard().then((res) => {
      setPrevCard({ ...res.results[0] });
    });
  }, []);

  const changeCard = () => {
    setShowChangeForm(true);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (activeTestType === "oneTimePayment") {
      setPaymentId(oneTimePayedTest?.id);
    } else {
      UserProfileAPI.getUserInfo().then((res) => {
        setUserInfo({ ...res });
      });
      setPaymentId(subscriptions?.[0]?.id);
    }
  }, [activeTestType]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const payWithUserCard = () => {
    if (activeTestType === "oneTimePayment") {
      PaymentsAPI.paymentForTest(paymentId)
        .then((res) => res?.id && history.push("/test-on-way/"))
        .catch((e) => setUpdateCardError(e?.data?.message));
    } else {
      PaymentsAPI.paymentSubscription({
        plan_id: paymentId,
        user_profile: { ...userInfo },
      })
        .then((res) => res?.id && history.push("/test-on-way/"))
        .catch((e) => setUpdateCardError(e?.data?.message));
    }
  };

  const updateUserCard = async () => {
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardNumberElement);
    const { token } = await stripe.createToken(cardElement);
    if (token) {
      await PaymentsAPI.updateDefaultCard(token.id)
        .then((res) => {
          setPrevCard({ ...res });
          setShowChangeForm(false);
        })
        .catch((e) => setUpdateCardError(e?.data?.message));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disabled
      // form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    const { token } = await stripe.createToken(cardElement);
    if (token) {
      await PaymentsAPI.updateDefaultCard(token.id)
        .then((res) => {
          res?.id && payWithUserCard();
        })
        .catch((e) => setUpdateCardError(e?.data?.message));
    }
  };

  return (
    <div className={s.ConfirmTestForm}>
      <div className="container">
        {prevCard.last4 ? (
          <>
            <div className={s.prevCard}>
              <div className={s.prevCardWrapper}>
                <div>**** **** **** {prevCard.last4}</div>
                <div>
                  {prevCard.exp_month}/{prevCard.exp_year}
                </div>
              </div>
              <CloseMobileMenuIcon onClick={() => changeCard()} />
            </div>
            {!showChangeForm && (
              <button
                className="btn btn-orange"
                type="button"
                onClick={payWithUserCard}
              >
                Pay
              </button>
            )}
            {showChangeForm && (
              <form>
                <StripeCardNumber
                  onChange={onChange}
                  errorMessage={errors.cardNumber?.message}
                />
                <StripeCardCvc
                  onChange={onChange}
                  errorMessage={errors.cardCvc?.message}
                />
                <StripeExpiryDate
                  onChange={onChange}
                  errorMessage={errors.cardExpiry?.message}
                />
                <button
                  className="btn btn-orange"
                  type="button"
                  onClick={updateUserCard}
                >
                  Update Card
                </button>
              </form>
            )}
          </>
        ) : (
          <form onSubmit={handleSubmit}>
            <StripeCardNumber
              onChange={onChange}
              errorMessage={errors.cardNumber?.message}
            />
            <StripeCardCvc
              onChange={onChange}
              errorMessage={errors.cardCvc?.message}
            />
            <StripeExpiryDate
              onChange={onChange}
              errorMessage={errors.cardExpiry?.message}
            />
            {updateCardError && (
              <span className={s.cardError}>{updateCardError}</span>
            )}
            <p>
              By confirming you agree to our Terms & Conditions and understand
              that you will be billed in accordance with the subscriptions on
              your account. This will change you current payment details.
            </p>
            <button className="btn btn-orange" type="submit">
              Submit
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

const ConfirmTestFormWrapper = () => {
  return (
    <Elements stripe={stripePromise}>
      <ConfirmTestForm />
    </Elements>
  );
};

export default ConfirmTestFormWrapper;
