import { getRequest } from './base';
import store from "../store"
import { saveTestsInfo, saveUserTests } from "../store/actions/tests"
import { getUserTestsFinished, getUserTestsStarted } from '../store/actions/loaders';

export class TestsAPI {
  static async getTests() {
    const response = await getRequest({ url: "api/tests/" });
    store.dispatch(saveTestsInfo(response.results?.[0]))
  }

  static async getUserTests() {
    store.dispatch(getUserTestsStarted());
    try {
      const { results } = await getRequest({
        url: "api/tests/user-test/",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      });
      store.dispatch(saveUserTests(results))
      store.dispatch(getUserTestsFinished());
    } catch (error) {
      // 
      store.dispatch(getUserTestsFinished());
    }
  }
}