import React, { useEffect } from "react";
import { Link, Route } from "react-router-dom";

import s from "./Footer.module.scss";

// components
import Logo from "../../components/Logo";
import { ReactComponent as FacebookIcon } from "../../assets/svg/facebook-icon.svg";
import { ReactComponent as InstaIcon } from "../../assets/svg/instagram.svg";
import { useSelector } from "react-redux";
import { FooterAPI } from "../../api";
import { GET_TEST_PATH } from "../../routes/constants";

const Footer = ({dataStyle = null, withGeometry = false}) => {
  const footerList = useSelector(({ footer }) => footer.list);
  useEffect(() => {
    if(!footerList.length) {
      FooterAPI.getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <footer className={`${s.footer} ${withGeometry && s.geometry}`} data-style={dataStyle}>
      <div className={s.footerContent}>
        <div className={s.footerRight}>
          <div className={s.footerCols}>
            <div className={s.footerLinks}>
              <div className={s.footerCol}>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/our-story">About us</Link>
                  </li>
                  <li>
                    <Link to="#" onClick={(e) => {
                      window.location = 'mailto:help@matter-health.com';
                      e.preventDefault();
                    }}>Contact us</Link>
                  </li>
                </ul>
              </div>

              <div className={s.footerCol}>
                <ul>
                  {footerList.map(footerLink => (
                    <li key={footerLink.name}>
                      <a href={footerLink.file} rel="noreferrer noopener" target="_blank">{footerLink.name}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className={s.socialWrapper}>
              <div className={s.footerCol}>Follow us on social</div>
              <div className={s.iconsWrapper}>
                <a
                    href="https://www.facebook.com/matterhealth"
                    target="_blank"
                    rel="noreferrer"
                >
                  <FacebookIcon />
                </a>
                <a
                    href="https://www.instagram.com/matter_health/"
                    target="_blank"
                    rel="noreferrer"
                >
                  <InstaIcon />
                </a>
              </div>
            </div>
          </div>
          <Link className="btn btn-orange" to={GET_TEST_PATH}>
            Get Started
          </Link>
        </div>
        <div className={s.footerLogoWrapper}>
          <Logo isHeaderLogo={false} />
        </div>
      </div>

      <p className={s.copywright}>&copy; 2021 Matter Health</p>
    </footer>
  );
};

export default Footer;
