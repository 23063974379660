import React from "react";
import { Link } from "react-router-dom";
import {
  Header,
  Footer,
  Accordion,
  Steps,
  HomeSliders,
} from "../../components";

import s from "./Homepage.module.scss";

const Homepage = () => {
  return (
    <div className={s.Homepage}>
      <div className={s.hero}>
        <Header />

        <div className={s.heroContent}>
          <div className={s.heroImage}>
            <h2>
              Your boyfriend
              <br />– <span>matters</span>
            </h2>
          </div>

          <div className={s.heroText}>
            <div className={s.heroDescription}>
              <h3>
                Bowel cancer is the UK’s 3rd most common cancer.
                <p>
                  If caught early 90% of cases are treated successfully with
                  full recovery.
                </p>
                Order your test today.
              </h3>

              <Link className="btn" to="/get-test">
                Order a test
              </Link>
            </div>

            <div className={s.priceCircle} />
          </div>
        </div>
      </div>

      <section className={s.howItWorks}>
        <div className="container">
          <h4>How our tests work</h4>

          <Steps />
        </div>
      </section>

      <section className={s.trustedWorldwide}>
        <div className="container">
          <div className={s.trustedWorldwideContainer}>
            <div className={s.trustedWorldwideText}>
              <h2>HCA – Trusted worldwide</h2>
              <p>
                With nearly 300 hospitals across the US and UK, 240,000 nurses
                and 37,000 doctors, HCA is the world’s largest private hospital
                group, handling 27 million patient encounters each year. In the
                UK HCA has built a world-class network of six hospitals in
                London, two hospitals in Manchester and is building a hospital
                in Birmingham, in addition to independent world class UKAS
                accredited laboratories. Read more about HCA at{" "}
                <a href="https://www.hcahealthcare.co.uk/about-hca-uk">
                  https://www.hcahealthcare.co.uk/about-hca-uk
                </a>
              </p>
            </div>

            <div className={s.trustedWorldwideImage}>
              <img
                src="images/trustedWorldwide.jpg"
                srcSet="images/trustedWorldwide.jpg, images/trustedWorldwide@2x.jpg 2x, images/trustedWorldwide@3x.jpg 3x"
                alt="Trusted worldwide"
              />
            </div>
          </div>

          <div className={s.bowelCancer}>
            <div className={s.bowelCancerText}>
              <h4>About bowel cancer</h4>
              <p>
                Bowel cancer is also called colorectal cancer. It affects the
                large bowel, which is made up of the colon and rectum. The cells
                in your body normally divide and grow in a controlled way. When
                cancer develops, the cells change and can grow in an
                uncontrolled way. Most bowel cancers develop from pre-cancerous
                growths, called polyps. But not all polyps develop into cancer.
                If your doctor finds any polyps, he or she can remove them to
                prevent them becoming cancerous. Cancer cells may stay in the
                bowel or they might spread to other parts of the body, like the
                liver or lungs.
              </p>
            </div>

            <a
              className="btn btn-orange"
              href="https://www.bowelcanceruk.org.uk/campaigning/early-diagnosis/end-the-capacity-crisis/"
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </a>
          </div>
        </div>
      </section>

      <HomeSliders.Numbers />

      <section className={s.story}>
        <div className="container">
          <div className={s.storyContent}>
            <h4>Mark & Ben</h4>
            <h1>Our story</h1>
            <h2>“If we save one persons life”</h2>
            <p>
              Matter Health was created because our founders, Ben and Mark, lost
              a close friend who died of bowel cancer, exactly in the age group
              the NHS reckons don’t need regular testing. Losing our friend made
              us want to learn more about the simple test people can take to
              spot early if there's a problem that needs treatment. From that
              journey of discovery we wanted to do something that can help more
              people become aware of the risks of bowel cancer and avoid
              discovering any issues until it is too late.
            </p>
            <Link className="btn" to="/our-story">
              Read more
            </Link>
          </div>

          <div className={s.storyImg}>
            <img
              src="images/homepage-story.png"
              srcSet="images/homepage-story.png, images/homepage-story@2x.png 2x, images/homepage-story@3x.png 3x"
              // srcSet="images/our-story.png, images/our-story@2x.png 1126w, images/our-story@3x.png 1689w"
              // sizes="(max-width: 992px) 1  30vw, (min-width: 992px) 40vw, 100vw"
              alt="our story"
            />
          </div>
        </div>
      </section>
      {/* https://deazy.atlassian.net/jira/software/projects/MHP2/boards/19?selectedIssue=MHP2-114 */}
      {/* <section className={s.testimonials}>
        <div className="container">
          <h4>Don’t just take it from us</h4>
          <HomeSliders.Articles />
        </div>
      </section> */}
      <div className="container">
        <div className={s.btnWrapper}>
          <Link to="/get-test" className="btn btn-orange">
            Take a test
          </Link>
        </div>
      </div>

      <section className={s.faq}>
        <div className="container">
          <h2>Faqs</h2>

          <Accordion />
        </div>
      </section>

      <Footer withGeometry />
    </div>
  );
};

export default Homepage;
