import { getRequest } from './base';
import store from '../store';
import { getSubscriptions, getUserSubscription } from '../store/actions/subscriptions';
import { getUserSubscriptionFinished, getUserSubscriptionStarted } from '../store/actions/loaders';

export class SubscriptionsAPI {
  static async getPlans() {
    const response = await getRequest({ url: "api/subscriptions/plans/" });
    store.dispatch(getSubscriptions(response))
  }

  static async getUserSubscription() {
    store.dispatch(getUserSubscriptionStarted())
    try {
      const response = await getRequest({
        url: "api/subscriptions/user-subscription/",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      });
      store.dispatch(getUserSubscription(response))
      store.dispatch(getUserSubscriptionFinished())
    } catch (error) {
      store.dispatch(getUserSubscriptionFinished())
    }
  }
}