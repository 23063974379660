import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import s from "./ResetPasswordForm.module.scss";
// components
import FormField from "../../components/FormField";
import useAuthorization from "../../hooks/useAuthorization";
import Footer from "../../components/Footer";
import Logo from "../../components/Logo";
import { useParams } from "react-router-dom";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import FormikErrorFocus from "formik-error-focus";

const validationSchema = Yup.object({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Should be minimum 8 characters.")
    .test(
      "password",
      "Should be minimum 1 letter and 1 number.",
      (value) =>
        value !== undefined &&
        value.match("[0-9]") &&
        value.match("[a-zA-Z]") &&
        value?.split("")[0] !== " " &&
        value?.split("")[value.length - 1] !== " "
    ),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

const ResetPasswordForm = () => {
  const { uid, token } = useParams();
  const { changePassword } = useAuthorization();
  const [password, setPassword] = useState("");
  const handleChangePass = (event) => {
    const { name, value } = event;
    if (name === "password") {
      setPassword(value);
    }
  };
  return (
    <>
      <div className={s.reset}>
        <div className={s.loginHeader}>
          <Logo />
        </div>
        <div className={s.content}>
          <div className={s.resetWrapper}>
            <h3 className={s.title}>Reset password</h3>
            <Formik
              initialValues={{
                password: "",
                passwordConfirmation: "",
              }}
              validationSchema={validationSchema}
              onSubmit={async (values, { setSubmitting, setErrors }) => {
                changePassword({ ...values, uid, token, setErrors });
                setSubmitting(false);
              }}
            >
              <Form onChange={(e) => handleChangePass(e.target)}>
                <FormField
                  label="New password"
                  name="password"
                  type="password"
                />
                <FormField
                  label="Please re-enter password"
                  name="passwordConfirmation"
                  type="password"
                />
                <ProgressBar password={password} />
                <div className={s.errorText}>
                  Minimum of 8 characters
                  <br />1 letter and 1 number
                </div>

                <div className={s.buttonWrapper}>
                  <button className="btn btn-orange" type="submit">
                    Update
                  </button>
                </div>
                <FormikErrorFocus
                  offset={-35}
                  align={"top"}
                  focusDelay={200}
                  ease={"linear"}
                  duration={500}
                />
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <Footer dataStyle={"withoutEllipse"} />
    </>
  );
};

export default ResetPasswordForm;
