export const LOG_OUT = "LOG_OUT";
export const AUTHORIZATION = "AUTHORIZATION";
export const UPDATE_LOADING_STATUS = "UPDATE_LOADING_STATUS"
export const LOGIN_ERROR = "LOGIN_ERROR"
//USER INFO
export const SAVE_USER_CARD = "SAVE_USER_CARD";
export const SAVE_USER_INFO = "SAVE_USER_INFO";
export const SAVE_USER_STRIPE_SUBSCRIPTION = "SAVE_USER_STRIPE_SUBSCRIPTION"
//TESTS
export const SAVE_TESTS_INFO = "SAVE_TESTS_INFO";
export const SAVE_USER_TESTS = "SAVE_USER_TESTS";
export const UPDATE_TEST_TYPE = "UPDATE_TEST_TYPE";
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD'
export const RESET_PASSWORD = 'RESET_PASSWORD'
//SUBSCRIPTIONS
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";
export const GET_USER_SUBSCRIPTION = "GET_USER_SUBSCRIPTION";
// FOOTER
export const SET_FOOTER_LIST = "SET_FOOTER_LIST";
