import React from "react";
import FormikErrorFocus from 'formik-error-focus'
import { Formik, Form } from "formik";
import * as Yup from "yup";

import s from "./PasswordRecoveryForm.module.scss";

// components
import FormField from "../../components/FormField";
import useAuthorization from "../../hooks/useAuthorization";

const validationSchema = Yup.object({
  email: Yup.string().required("Required"),
});

const PasswordRecovery = () => {
  const {
    reset
  } = useAuthorization();
  return (
    <div className={s.PasswordRecovery}>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            reset(values)

            setSubmitting(false);
          }, 400);
        }}
      >
        <Form>
          <FormField label="Email address" name="email" type="email" />
          <div className={s.loginButtons}>
            <button className="btn" type="submit">
              EMAIL REMINDER
            </button>
          </div>
          <FormikErrorFocus
              offset={-35}
              align={'top'}
              focusDelay={200}
              ease={'linear'}
              duration={500}
          />
        </Form>
      </Formik>
    </div>
  );
};

export default PasswordRecovery;
