import {
  AUTHORIZATION,
  LOG_OUT,
  RESET_PASSWORD,
  RECOVER_PASSWORD,
  GET_USER_SUBSCRIPTION,
  SAVE_USER_INFO,
  SAVE_USER_CARD,
  SAVE_USER_STRIPE_SUBSCRIPTION,
  LOGIN_ERROR,
} from "../../constants.js";

const initialState = {
  isAuthorized: !!localStorage.getItem("token"),
  billingInfo: null,
  id: null,
  phone_number: null,
  user: null,
  subscription: null,
  card: null,
  stripeSubscription: null,
  loginError: '',
};

function userReducer(state = initialState, { type, payload }) {
  switch (type) {
    case AUTHORIZATION:
      return {
        ...state,
        isAuthorized: true,
        loginError: '',
      };
    case LOG_OUT:
      return {
        ...initialState,
        isAuthorized: false,
        loginError: '',
      };
    case RECOVER_PASSWORD:
    case RESET_PASSWORD:
      return initialState;
    case SAVE_USER_INFO:
      return {
        ...state,
        ...payload,
        isAuthorized: !!localStorage.getItem("token"),
        loginError: '',
      };
    case SAVE_USER_CARD:
      return {
        ...state,
        card: payload,
      };
    case SAVE_USER_STRIPE_SUBSCRIPTION:
      return {
        ...state,
        stripeSubscription: payload,
      };
    case GET_USER_SUBSCRIPTION:
      return {
        ...state,
        isAuthorized: !!localStorage.getItem("token"),
        subscription: {
          count: payload.count,
          next: payload.next,
          previous: payload.previous,
          subscriptions: [...payload.results],
        },
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loginError: payload,
      };
    default:
      return state;
  }
}

export default userReducer;
