import React from "react";
import { Field, ErrorMessage } from "formik";
import InputMask from "react-input-mask";

import s from "./FormField.module.scss";

const FormField = ({
  label = "",
  fieldType = "text",
  name = "",
  onChange,
  value,
  required = false,
  mobile = false,
  ...rest
}) => {
  if (mobile) {
    return (
      <div className={s.formGroup}>
        {label && <span className={s.inputLabel}>{label}</span>}
        <label className={s.inputLabel} htmlFor={name}>
          <div className={s.container}>
            <span className={s.label}>+44</span>
            <InputMask
              value={value}
              onChange={(e) => {
                const value = e.target.value || "";
                onChange(name, value);
              }}
              mask="999 999 9999"
              maskChar=""
            >
              {() => (
                <input
                  id={name}
                  className={s.inputPhone}
                  name={name}
                  type={fieldType}
                  {...rest}
                />
              )}
            </InputMask>
          </div>
        </label>
        <ErrorMessage
          className={s.inputErrorMessage}
          component="p"
          name={name}
        />
      </div>
    );
  }
  return (
    <div className={s.formGroup}>
      <label className={s.inputLabel} htmlFor={name}>
        {label}
        {required ? <span> *</span> : null}
      </label>
      <Field
        id={name}
        className={s.input}
        name={name}
        type={fieldType}
        {...rest}
      />
      <ErrorMessage className={s.inputErrorMessage} component="p" name={name} />
    </div>
  );
};

export default FormField;
