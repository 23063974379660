import React from "react";

import s from "./Layout.module.scss";

// components
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Layout = ({ children, withGeometry = false }) => {
  return (
    <div className={s.Layout}>
      <div className={s.LayoutHeader}>
        <Header />
      </div>

      {children}

      <Footer withGeometry={withGeometry}  />
    </div>
  );
};

export default Layout;
