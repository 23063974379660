import React, { useEffect, useState } from "react";
import { Switch, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import PrivateRoute from "../../routes/PrivateRoute";

import s from "./Account.module.scss";

// components
import Layout from "../../components/Layout";
import PageHeading from "../../components/PageHeading";
import BillingForm from "../../forms/billing";
import ChangePasswordForm from "../../forms/change-password";
import AccountDetailsForm from "../../forms/account-details";
import { PaymentsAPI } from "../../api/payments";
import { UserProfileAPI } from "../../api/userProfile";
import { prettyDate } from "../../helpers";

const Account = () => {
  const userProfile = useSelector(({ user }) => user);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [expirationDate, setExpirationDate] = useState(null);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    PaymentsAPI.paymentHistory().then((res) => {
      setPaymentHistory(res.results);
    });
    if (!userProfile.user) {
      UserProfileAPI.getUserInfo();
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    setExpirationDate(
      userProfile.subscription?.subscriptions[0]?.expiration_date !==
        undefined &&
        prettyDate(
          new Date(userProfile.subscription?.subscriptions[0]?.expiration_date)
        )
    );
  }, [userProfile.subscription?.subscriptions]);

  const sort = (a, b) => {
    const dateA = new Date(a?.payment_date);
    const dateB = new Date(b?.payment_date);
    return dateB - dateA;
  };

  const renderPayments = (payment, index) => {
    return (
      <div className={s.prevPayment} key={index}>
        <div>
          <span>Num</span>
          {index + 1}
        </div>
        <div>
          <span>Date</span>
          {prettyDate(payment.payment_date)}
        </div>
        <div>
          <span>Subscription</span>
          {payment.plan ? "Subscription" : "One off payment"}
        </div>
        <div>
          <span>Amount</span>£25.00
        </div>
        <div>
          <span>Approved</span>Yes
        </div>
      </div>
    );
  };
  return (
    <Layout>
      <div className={s.account}>
        <div className={s.content}>
          <PageHeading>
            <h2>My account</h2>
          </PageHeading>
          {expirationDate && (
            <div className={s.expirationDate}>
              Subscription ends: {expirationDate}
            </div>
          )}
          <div className={s.accountNav}>
            <NavLink
              to="/account"
              className={s.navItem}
              activeClassName={s.selected}
              exact
            >
              Billing and payments
            </NavLink>
            <NavLink
              to="/account/change-password"
              className={s.navItem}
              activeClassName={s.selected}
            >
              CHANGE PASSWORD
            </NavLink>
            <NavLink
              to="/account/details"
              className={s.navItem}
              activeClassName={s.selected}
            >
              ACCOUNT DETAILS
            </NavLink>
          </div>

          <div className={s.accountForms}>
            <Switch>
              <PrivateRoute path="/account" exact>
                <h3>Card details</h3>
                <BillingForm />
                <div className="container">
                  <div className={s.prevPayments}>
                    <h3>Previous payments</h3>

                    <div className={s.prevPaymentsTable}>
                      <div className={s.prevPayment}>
                        <div>Num</div>
                        <div>Date</div>
                        <div>Payment</div>
                        <div>Amount</div>
                        <div>Approved</div>
                      </div>
                      {paymentHistory
                        ? paymentHistory
                            .sort(sort)
                            .map((payment, index) =>
                              renderPayments(payment, index)
                            )
                        : "Loading..."}
                    </div>
                  </div>
                </div>
              </PrivateRoute>
              <PrivateRoute path="/account/change-password">
                <ChangePasswordForm />
              </PrivateRoute>
              <PrivateRoute path="/account/details">
                <AccountDetailsForm userProfile={userProfile} />
              </PrivateRoute>
            </Switch>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Account;
