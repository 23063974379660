import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import s from "./ChangePasswordForm.module.scss";

// components
import FormField from "../../components/FormField";
import { UserProfileAPI } from "../../api/userProfile";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import FormikErrorFocus from "formik-error-focus";
import Toast from "../../components/Toast/Toast";

const validationSchema = Yup.object({
  old_password: Yup.string()
    .min(8, "Minimum of 8 characters 1 letter and 1 number")
    .matches(
      /(?=.*[a-zA-Z])(?=.*[0-9])/,
      "Minimum of 8 characters 1 letter and 1 number"
    )
    .required("field required"),
  new_password1: Yup.string()
    .min(8, "Minimum of 8 characters")
    .test(
      "password",
      "The password cannot start or end with a space.",
      (value) =>
        value?.split("")[0] !== " " &&
        value?.split("")[value.length - 1] !== " "
    )
    .matches(
      /(?=.*[a-zA-Z])(?=.*[0-9])/,
      "Minimum of 8 characters 1 letter and 1 number"
    )
    .required("Required"),
  new_password2: Yup.string()
    .oneOf([Yup.ref("new_password1"), null], "Passwords must match")
    .required("Required"),
});

const initialValues = {
  old_password: "",
  new_password1: "",
  new_password2: "",
};

const ChangePasswordForm = () => {
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState(null);
  const handleChangePass = (event) => {
    const { name, value } = event;
    if (name === "new_password1") {
      setPassword(value);
    }
  };
  const handleSubmit = (values, setErrors, resetForm) => {
    UserProfileAPI.changeAccountPassword({ ...values, setErrors })
      .then((res) => {
        if (res.detail === "New password has been saved.") {
          setMessage("Password  changed!");
          resetForm({});
        }
      })
      .catch((e) => {
        setErrors(
          Object.fromEntries(
            Object.entries({ ...e.data }).map(([key, value]) => [key, value])
          )
        );
      });
  };
  return (
    <div className={s.ChangePasswordForm}>
      <div className="container">
        <Toast message={message} clearMessage={setMessage} />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
            handleSubmit(values, setErrors, resetForm);
            setSubmitting(false);
          }}
        >
          <Form onChange={(e) => handleChangePass(e.target)}>
            <FormField
              label="Current password"
              name="old_password"
              type="password"
            />
            <FormField label="Password" name="new_password1" type="password" />
            <FormField
              label="Please re-enter password"
              name="new_password2"
              type="password"
            />
            <ProgressBar password={password} />
            <div className={s.errorText}>
              Minimum of 8 characters
              <br />1 letter and 1 number
            </div>
            <button className="btn btn-orange" type="submit">
              Update
            </button>
            <FormikErrorFocus
              offset={-35}
              align={"top"}
              focusDelay={200}
              ease={"linear"}
              duration={500}
            />
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ChangePasswordForm;
