import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import s from "./GetTestForm.module.scss";

// components
import FormField from "../../components/FormField";
import { useGetTestForm } from "./useGetTestForm";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import FormikErrorFocus from "formik-error-focus";

const validationSchema = Yup.object({
  firstName: Yup.string()
    .required("Required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
    .max(149, "Maximum 150 letters."),
  lastName: Yup.string()
    .required("Required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
    .max(149, "Maximum 150 letters."),
  email: Yup.string().email().required("Required"),
  phone_number: Yup.string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .min(12, "Phone number is not valid")
    .max(12, "Phone number is not valid"),
  password: Yup.string()
    .min(8, "Minimum of 8 characters")
    .test(
      "password",
      "The password cannot start or end with a space.",
      (value) =>
        value?.split("")[0] !== " " &&
        value?.split("")[value.length - 1] !== " "
    )
    .matches(
      /(?=.*[a-zA-Z])(?=.*[0-9])/,
      "Minimum of 8 characters 1 letter and 1 number"
    )
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
  addressLineOne: Yup.string()
    .required("Required")
    .matches(
      /^[A-Za-z0-9][A-Za-z0-9 ]*[A-Za-z0-9]*$/,
      "Only letters and numbers are allowed"
    )
    .max(50, "Maximum 50 letters."),
  addressLineSecond: Yup.string()
    .matches(
      /^[A-Za-z0-9][A-Za-z0-9 ]*[A-Za-z0-9]*$/,
      "Only letters and numbers are allowed"
    )
    .max(50, "Maximum 50 letters.")
    .required("Required"),
  city: Yup.string()
    .required("Required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
    .max(100, "Maximum 100 letters."),
  country: Yup.string()
    .required("Required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
    .max(50, "Maximum 50 letters."),
  postcode: Yup.string()
    .required("Required")
    .matches(
      /^[A-Za-z0-9][A-Za-z0-9 ]*[A-Za-z0-9]*$/,
      "Only letters and numbers are allowed"
    )
    .max(50, "Maximum 50 letters."),
});

const GetTestForm = () => {
  const { initialValues, onSubmit } = useGetTestForm();
  const [password, setPassword] = useState("");
  const handleChangePass = (event) => {
    const { name, value } = event;
    if (name === "password") {
      setPassword(value);
    }
  };

  return (
    <div className={s.GetTest}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          onSubmit(
            {
              ...values,
              phone_number: values?.phone_number
                ?.replace(/\)/g, "")
                .replace(/\(/g, "")
                .replace(/-/g, "")
                .replace(/ /g, ""),
            },
            setErrors
          );
          setSubmitting(false);
        }}
      >
        {({ setFieldValue, values }) => (
          <Form
            className={s.getTestForm}
            onChange={(e) => handleChangePass(e.target)}
          >
            <div className="container">
              <FormField label="First Name" name="firstName" type="text" />
              <FormField label="Last Name" name="lastName" type="text" />
              <FormField label="Email" name="email" type="email" />
              <FormField
                onChange={setFieldValue}
                value={values?.phone_number}
                mobile
                label="Mobile number"
                name="phone_number"
                type="text"
              />
              <p>
                NOTE: We only use your number to text you about your results and
                won't share it with anyone else.
              </p>

              <FormField
                label="Account password"
                name="password"
                type="password"
              />
              <FormField
                label="Re-enter password"
                name="confirmPassword"
                type="password"
              />
              <ProgressBar password={password} />
              <div className={s.errorText}>
                Minimum of 8 characters
                <br />1 letter and 1 number
              </div>
            </div>

            <div className={s.billingAddress}>
              <div className="container">
                <h4>Billing Address</h4>

                <FormField
                  label="Address line 1"
                  name="addressLineOne"
                  type="text"
                  required
                />
                <FormField
                  label="Address line 2"
                  name="addressLineSecond"
                  type="text"
                  required
                />
                <FormField label="City" name="city" type="text" required />
                <FormField label="Country" name="country" type="text" required />
                <FormField
                  label="Postcode"
                  name="postcode"
                  type="text"
                  required
                />

                <button className="btn btn-orange" type="submit">
                  Submit
                </button>
                <FormikErrorFocus
                  offset={-35}
                  align={"top"}
                  focusDelay={200}
                  ease={"linear"}
                  duration={500}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default GetTestForm;
