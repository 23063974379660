import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import s from "./Banner.module.scss";

// Assets
import { ReactComponent as FacebookIcon } from "../../assets/svg/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../assets/svg/twitter-icon.svg";
import { ReactComponent as EmailIcon } from "../../assets/svg/email-icon.svg";
import { ReactComponent as ShareIcon } from "../../assets/svg/social-share-icon.svg";
import { objectToGetParams } from "../../helpers/objectToGetParams";

const sharedUrl = "https://matter-health.com/";
const quote =
  "Your health matters! It matters to you, to your family and your friends, and it matters to us too. You can order easy-to-use bowel screening tests that will look for signs that could point to cancerous or pre-cancerous growth. Find out more";

const Banner = () => {
  const shareTwitter = useCallback(() => {
    const urlToShare =
      "https://twitter.com/share" +
      objectToGetParams({
        url: sharedUrl,
        text: quote,
      });
    window.open(urlToShare, "sharer");
  }, []);

  const shareFacebook = useCallback(() => {
    const urlToShare =
      "https://facebook.com/sharer.php" +
      objectToGetParams({
        u: sharedUrl,
        quote,
      });
    window.open(urlToShare, "sharer");
  }, []);

  const share = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Matter Health",
          url: sharedUrl,
        })
        .catch((e) => console.error(e));
    } else {
      return;
    }
  };
  return (
    <div className={s.Banner}>
      <div className={s.bannerContent}>
        <h2>Be a hero</h2>
        <h4>Encourage your friends and family to do the same</h4>
        <div className={s.bannerSocialButtons}>
          <button className="btn btn-orange" onClick={shareFacebook}>
            <FacebookIcon />
          </button>
          <button className="btn btn-orange" onClick={shareTwitter}>
            <TwitterIcon />
          </button>
          <Link
            className="btn btn-orange"
            to="#"
            onClick={(e) => {
              window.location = `mailto:help@matter-health.com?subject=Matter-Health&body=${quote}`;
              e.preventDefault();
            }}
          >
            {" "}
            <EmailIcon />
          </Link>
          <span className="btn btn-orange" onClick={share}>
            <ShareIcon />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Banner;
