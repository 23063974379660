import React from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

// pages
import Homepage from "../pages/Homepage";
import OurStory from "../pages/OurStory";
import Account from "../pages/Account";
import TestOnWay from "../pages/TestOnWay";
import TestResults from "../pages/TestResults";
import Login from "../pages/Login";
import GetTest from "../pages/GetTest";
import ResetPasswordForm from "../forms/choose-new-password";
// urls names
import {
  GET_TEST_PATH,
  HOME_PATH,
  TEST_RESULTS_PATH,
  TEST_ON_THE_WAY_PATH,
  CHANGE_PASSWORD_PATH,
  LOGIN_PATH,
  ACCOUNT_PATH,
  ABOUT_US_PATH,
} from "./constants";

export const RenderRoutes = () => {
  return (
    <>
      <Route component={Homepage} path={HOME_PATH} exact={true} />
      <Route component={Login} path={LOGIN_PATH} />
      <Route component={ResetPasswordForm} path={CHANGE_PASSWORD_PATH} />
      <Route component={OurStory} path={ABOUT_US_PATH} />
      <Route component={GetTest} path={GET_TEST_PATH} />
      <PrivateRoute component={Account} path={ACCOUNT_PATH} />
      <PrivateRoute component={TestOnWay} path={TEST_ON_THE_WAY_PATH} />
      <PrivateRoute component={TestResults} path={TEST_RESULTS_PATH} />
    </>
  );
};
