import axios from "axios";
import { cookieWatcher } from "../helpers";
import history from "../history";

const HTTP_STATUS = {
  UNAUTHORIZED: 401,
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
};

const DOMAIN =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000"
    : process.env.REACT_APP_API_BASE_URI;

export const http = axios.create({
  baseURL: DOMAIN,
  headers: { "Content-Type": "application/json" },
});

http.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const { response } = error;

    if (!response) {
      return Promise.reject(response);
    }

    if (response?.status === HTTP_STATUS.UNAUTHORIZED) {
      cookieWatcher(({ oldValue, newValue }) => {
        if (newValue.includes("first_load=true")) {
          history.push("/");
        } else if (newValue.includes("login=true")) {
          history.push("/test-results");
        } else if (newValue.includes("login=false")) {
          history.push("/login");
        }
      }, 1000);
      localStorage.clear();
    }

    if (response?.status >= HTTP_STATUS.SERVER_ERROR) {
      console.warn(
        "Something went wrong cause of next error: ",
        error.response,
        "\n",
        error.config
      );
    }

    return Promise.reject(response);
  }
);
