import { UPDATE_LOADING_STATUS, LOG_OUT } from "../../constants";

const initialState = {
  userTestsLoading: false,
  userSubcriptionLoading: false,
  logsInLoading: false,
};

const loadersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_LOADING_STATUS:
      return {
        ...state,
        [payload.name]: payload.status,
      };
    case LOG_OUT:
      return initialState;
    default:
      return state;
  }
};

export default loadersReducer;
