import { combineReducers } from "redux";
import user from "./user";
import subscriptions from "./subscriptions";
import tests from "./tests"
import loaders from "./loaders"
import footer from "./footer"

export default combineReducers({
  user,
  subscriptions,
  tests,
  loaders,
  footer,
});
