import s from "./ProgressBar.module.scss";
import React, { useCallback, useEffect, useState } from "react";

const ProgressBar = ({ password }) => {
  const [progress, setProgress] = useState({
    width: "0%",
    color: "transparent",
  });
  const checkUpperCaseLetter = useCallback((str) => {
    let isPasswordHasUppercaseLetter = false;
    str?.split("").map((character) => {
      if (character === character.toUpperCase()) {
        isPasswordHasUppercaseLetter = true;
        return null;
      }
      return null;
    });
    return isPasswordHasUppercaseLetter;
  }, []);

  useEffect(() => {
    if (password.length < 3) {
      setProgress({
        width: "0%",
        color: "transparent",
      });
    }
    if (password.length >= 3 && password.length <= 8) {
      setProgress({
        width: "25%",
        color: "#DC143C",
      });
    }
    if (
      password.length > 8 &&
      password.length <= 12 &&
      password.match("[A-Za-z0-9]")
    ) {
      setProgress({
        width: "50%",
        color: "#ff6702",
      });
    }
    if (
      password.length > 12 &&
      password.length < 15 &&
      checkUpperCaseLetter(password) &&
      password.match("[A-Za-z0-9]")
    ) {
      setProgress({
        width: "75%",
        color: "#f5c71a",
      });
    }
    if (
      password.length >= 15 &&
      checkUpperCaseLetter(password) &&
      password.match("[A-Za-z0-9]")
    ) {
      setProgress({
        width: "75%",
        color: "#f5c71a",
      });
    }
    if (
      password.length >= 15 &&
      password.match("[!@#$%^&*()_+]") &&
      checkUpperCaseLetter(password) &&
      password.match("[A-Za-z0-9]")
    ) {
      setProgress({
        width: "100%",
        color: "#32CD32",
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  return (
    <>
      <div className={s.progressBarTitle}>Password strength:</div>
      <div className={s.progressBar}>
        <div
          className={s.progressLine}
          style={{ width: progress.width, backgroundColor: progress.color }}
        />
      </div>
    </>
  );
};

export default ProgressBar;
