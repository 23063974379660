import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SubscriptionsAPI } from "../../api/subscriptions";
import { TestsAPI } from "../../api/tests";
import { updateTestType } from "../../store/actions/tests";

function useGetTest () {
    const subscriptions = useSelector(({subscriptions}) => subscriptions.subscriptions);
    const {
      oneTimePayedTest,
      activeTestType
    } = useSelector(({tests}) => tests);
    
    const dispatch = useDispatch();

    useEffect(() => {
      SubscriptionsAPI.getPlans();
      TestsAPI.getTests()
    }, []);
  
    return {
        subscriptionPlan: subscriptions?.[0],
        oneTypePayment: oneTimePayedTest,
        activeTestType,
        changeTestType: (type) => dispatch(updateTestType(type))
    }
}

export default useGetTest