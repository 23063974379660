import {
    SAVE_TESTS_INFO,
    UPDATE_TEST_TYPE,
    SAVE_USER_TESTS
} from "../constants";

export const saveTestsInfo = (tests) => {
    return {
        type: SAVE_TESTS_INFO, 
        payload: tests
    }
}

export const saveUserTests = (tests) => {
    return {
        type: SAVE_USER_TESTS,
        payload: tests
    }
}

export const updateTestType = (type) => {
    return {
        type: UPDATE_TEST_TYPE, 
        payload: type
    }
}