import React from "react";
import { Link } from "react-router-dom";
import { PaymentsAPI } from "../../api/payments";
import { GET_TEST_PATH } from "../../routes/constants";

import s from "./BillingBanner.module.scss";

const Banner = ({ bannerStep, bannerSteps, setBannerStep, id }) => {
  const deleteSubscription = () => {
    PaymentsAPI.deleteUserSubscription(id).then(
      (res) =>
        res.message === "Subscription Removed" &&
        setBannerStep(bannerSteps.cancelledSubscription)
    );
  };
  if (bannerStep === bannerSteps.confirmCancel) {
    return (
      <div className={s.confirmCancel}>
        <h2>Cancel your subscription?</h2>
        <button
          className="btn btn-orange"
          type="button"
          onClick={deleteSubscription}
        >
          Confirm
        </button>
      </div>
    );
  }
  if (bannerStep === bannerSteps.cancelledSubscription) {
    return (
      <div className={s.cancelledSubscription}>
        <h2>
          Your subscription
          <br />
          has been cancelled
        </h2>
        <p>Order a test with a one off payment</p>
        <Link className="btn btn-orange" to={GET_TEST_PATH}>
          Order a test
        </Link>
      </div>
    );
  }
  if (bannerStep === bannerSteps.withoutSubscription) {
    return (
      <div className={s.withoutSubscription}>
        <h2>Take away the hassle of having to remember a test every year!</h2>
        <div className={s.contentWrapper}>
          <p>
            For the same cost as a one off test each year (or two coffee's each
            month), save the worry of remembering and recieve a test in kit
            every year.
          </p>
          <Link className="btn" to={GET_TEST_PATH}>
            SUBSCRIBE
          </Link>
        </div>
      </div>
    );
  }
};

export default Banner;
