import React, { useEffect, useState } from "react";
import s from "./BillingForm.module.scss";

// components
import {
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { PaymentsAPI } from "../../api/payments";
import StripeCardNumber from "../../components/StripeElements/CardNumberElement";
import StripeCardCvc from "../../components/StripeElements/CardCvcElement";
import StripeExpiryDate from "../../components/StripeElements/CardExpiryElement";
import { loadStripe } from "@stripe/stripe-js";
import { BillingBanner, Loader } from "../../components";
import { ReactComponent as CloseMobileMenuIcon } from "../../assets/svg/mobile-menu-exit-button.svg";
import { SubscriptionsAPI } from "../../api/subscriptions";
import { useSelector } from "react-redux";

const bannerSteps = {
  noBanner: null,
  confirmCancel: "confirmCancel",
  cancelledSubscription: "cancelledSubscription",
  withoutSubscription: "withoutSubscription",
};
const stripePromise = loadStripe(
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    : "pk_test_LgP2cHtHNrujtJOca1paFY59002asAcpqD"
);
const BillingForm = () => {
  const [updateCardError, setUpdateCardError] = useState(null);
  const [showChangeForm, setShowChangeForm] = useState(true);
  const [bannerStep, setBannerStep] = useState(bannerSteps.noBanner);
  const { subscription, card, stripeSubscription } = useSelector(
    ({ user }) => user
  );
  const stripe = useStripe();
  const elements = useElements();
  const [errors, setErrors] = useState({});
  const onChange = (value) => {
    setErrors({
      ...errors,
      [value.elementType]: value.error,
    });
  };

  useEffect(() => {
    if (subscription?.subscriptions?.length === 0) {
      return setBannerStep(bannerSteps.withoutSubscription);
    } else {
      return setBannerStep(bannerSteps.noBanner);
    }
  }, [subscription]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!subscription) {
      SubscriptionsAPI.getUserSubscription();
    }
    if (!card?.id) {
      PaymentsAPI.getPaymentCard();
    }
    if (!stripeSubscription) {
      PaymentsAPI.getUserSubscription();
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const changeCard = () => {
    setShowChangeForm(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardNumberElement);
    const { token } = await stripe.createToken(cardElement);
    if (token) {
      await PaymentsAPI.updateDefaultCard(token.id)
        .then(() => setShowChangeForm(false))
        .catch((e) => setUpdateCardError(e?.data?.message));
    }
  };

  if (subscription?.subscriptions) {
    return (
      <div className={s.BillingForm}>
        <div className="container">
          {card?.last4 ? (
            <>
              <div className={s.prevCard}>
                <div className={s.prevCardWrapper}>
                  <div>**** **** **** {card?.last4}</div>
                  <div>
                    {card?.exp_month}/{card?.exp_year}
                  </div>
                </div>
                {/* <CloseMobileMenuIcon onClick={() => changeCard()} /> */}
              </div>
              {/* {showChangeForm && ( */}
                <form>
                  <StripeCardNumber
                    onChange={onChange}
                    errorMessage={errors.cardNumber?.message}
                  />
                  <StripeCardCvc
                    onChange={onChange}
                    errorMessage={errors.cardCvc?.message}
                  />
                  <StripeExpiryDate
                    onChange={onChange}
                    errorMessage={errors.cardExpiry?.message}
                  />
                </form>
              {/* )} */}
            </>
          ) : (
            <form>
              <StripeCardNumber
                onChange={onChange}
                errorMessage={errors.cardNumber?.message}
              />
              <StripeCardCvc
                onChange={onChange}
                errorMessage={errors.cardCvc?.message}
              />
              <StripeExpiryDate
                onChange={onChange}
                errorMessage={errors.cardExpiry?.message}
              />
            </form>
          )}
          {(bannerStep === bannerSteps.noBanner ||
            bannerStep === bannerSteps.withoutSubscription) && (
            <div
              className={s.buttonsWrapper}
              data-style={bannerStep === bannerSteps.noBanner && "no-banner"}
            >
              {subscription?.subscriptions.length > 0 && (
                <button
                  className="btn btn-greyActive"
                  type="button"
                  onClick={() => setBannerStep(bannerSteps.confirmCancel)}
                >
                  Cancel Subscription
                </button>
              )}
              {(!card?.last4 || showChangeForm) && (
                <div className={s.errorWrapper}>
                  {updateCardError && <span>{updateCardError}</span>}
                  <button
                    className="btn btn-orange"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Update
                  </button>
                </div>
              )}
            </div>
          )}
          {bannerStep !== bannerSteps.noBanner && (
            <BillingBanner
              bannerStep={bannerStep}
              bannerSteps={bannerSteps}
              setBannerStep={setBannerStep}
              id={stripeSubscription?.id}
            />
          )}
        </div>
      </div>
    );
  } else return <div>{Loader.Small}</div>;
};

const BillingFormWrapper = () => {
  return (
    <Elements stripe={stripePromise}>
      <BillingForm />
    </Elements>
  );
};

export default BillingFormWrapper;
