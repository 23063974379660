import React from "react";
import { Link } from "react-router-dom";

import s from "./OurStory.module.scss";

// components
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const OurStory = () => {
  return (
    <div className={s.ourStory}>
      <div className={s.heroImage}>
        <Header />

        <div className={s.titleWrapper}>
          <h2>About us</h2>
        </div>
      </div>
      <div className={s.textContent}>
        <h3>
          Unfortunately Bowel cancer is affecting more and more people at
          increasingly younger ages. And the scientists aren’t sure why this is.
          Simply, nobody knows why it’s happening. There’s not yet enough
          evidence and not enough data to draw conclusions, but there are plenty
          of theories: from diet, pollution or even the amount of antibiotics we
          take.
        </h3>

        <div className={s.storyText}>
          <p>
            The NHS does an incredible job but it doesn’t have the resources to
            provide regular tests for those below 55 years. But the fact is
            there’s been a rise of nearly 8% in under 30s, almost 5% in under
            40s, and 2% in under 50s over the past two decades.
          </p>
          <p>
            And this is the reason Matter Health is here. Matter Health was
            created because our founders, Ben and Mark, lost a close friend who
            died of bowel cancer, exactly in the age group the NHS reckons don’t
            need regular testing. Losing our friend made us want to learn more
            about the simple test people can take to spot early if there's a
            problem that needs treatment. From that journey of discovery we
            wanted to do something that can help more people become aware of the
            risks of bowel cancer and avoid discovering any issues until it is
            too late.
          </p>
          <p>
            So we thought of creating a simple way to do a test every year, with
            a great partner onboard, to do the highest quality tests and at the
            lowest possible price point (i.e. we make no profit!). We think
            Matter Health can raise awareness and make it easier for everyone to
            do a repeat test each year by signing up to a subscription - so
            every year a test simply arrives on your doorstep.
          </p>
          <p>
            We also want to gather data (all anonymised) to share with
            researchers so we can start to piece together the puzzle, and help
            the medical profession better understand why greater numbers of
            younger people are getting bowel cancer.
          </p>
          <p>
            Not only will you be helping fight bowel cancer, but it will help
            you too, to ensure you’re free of any problems.
          </p>
          <p>
            You can even sign up friends and family. Bowel cancer doesn’t
            discriminate, and a simple test will ensure you and your loved ones
            aren’t going to be affected by it.
          </p>
          <p>Early diagnosis matters</p>
          <p>
            Nearly everybody who is diagnosed early with bowel cancer will beat
            it. That’s why regular testing really matters.
          </p>
          <p>
            Give a test a try - it’s really easy! Then tell a friend to get
            their S*it together and do it too!
          </p>
          <p>Keep healthy</p>
          <p>Ben & Mark</p>
        </div>
      </div>
      <div className={s.orderTest}>
        <div className={s.container} id="image">
          <div className={s.inner}>
            <div className={s.footerWrapper}>
              <h2>Bowel cancer is the UK’s 3rd most common cancer.</h2>
              <Link className="btn btn-orange" to="/get-test">
                Order a test
              </Link>
            </div>
            <div className={s.footerImage}></div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default OurStory;
