import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

// components
import { Logo } from "../";

// assets
import { ReactComponent as CloseMobileMenuIcon } from "../../assets/svg/mobile-menu-exit-button.svg";

import s from "./Header.module.scss";
import useAuthorization from "../../hooks/useAuthorization";
import {
  ABOUT_US_PATH,
  ACCOUNT_PATH,
  LOGIN_PATH,
  TEST_RESULTS_PATH,
} from "../../routes/constants";
import { cookieWatcher } from "../../helpers";

const Header = () => {
  const { isAuthorized, logOut } = useAuthorization();
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);
  const [isUserHasToken, setIsUserHasToken] = useState(
    localStorage.getItem("token")
  );

  useEffect(() => {
    cookieWatcher(({ oldValue, newValue }) => {
      if (newValue.includes("login=true")) {
        setIsUserHasToken(true);
      } else if (newValue.includes("login=false")) {
        setIsUserHasToken(false);
      }
    }, 1000);
  }, []);

  const handleMobileMenuCLick = () => {
    setIsMobileMenuOpened((prevState) => !prevState);
  };
  return (
    <header className={s.header}>
      <Logo />

      <nav className={s.headerNav}>
        <NavLink activeClassName={s.activeLink} to="/our-story">
          Our story
        </NavLink>

        {isAuthorized && isUserHasToken ? (
          <>
            <NavLink activeClassName={s.activeLink} to={TEST_RESULTS_PATH}>
              My tests
            </NavLink>
            <NavLink activeClassName={s.activeLink} to={ACCOUNT_PATH}>
              Account
            </NavLink>
          </>
        ) : null}

        {isAuthorized && isUserHasToken ? (
          <button className={s.headerBtn} onClick={logOut}>
            Log Out
          </button>
        ) : (
          <Link className={s.headerBtn} to={LOGIN_PATH}>
            Log In
          </Link>
        )}
      </nav>
      <div className={s.mobileMenu} onClick={handleMobileMenuCLick}>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div
        className={`${s.mobileMenuContainer} ${
          isMobileMenuOpened ? s.menuOpened : ""
        }`}
      >
        <CloseMobileMenuIcon onClick={handleMobileMenuCLick} />

        <nav className={s.mobileMenuNav}>
          <NavLink
            className={s.mobileMenuLink}
            activeClassName={s.mobileMenuLinkActive}
            to="/"
            exact
          >
            Home
          </NavLink>
          <NavLink
            className={s.mobileMenuLink}
            activeClassName={s.mobileMenuLinkActive}
            to={ABOUT_US_PATH}
          >
            Our story
          </NavLink>

          {isAuthorized && isUserHasToken ? (
            <>
              <NavLink
                className={s.mobileMenuLink}
                activeClassName={s.mobileMenuLinkActive}
                to={TEST_RESULTS_PATH}
              >
                My tests
              </NavLink>
              <NavLink
                className={s.mobileMenuLink}
                activeClassName={s.mobileMenuLinkActive}
                to={ACCOUNT_PATH}
              >
                My account
              </NavLink>
            </>
          ) : null}
        </nav>

        {isAuthorized && isUserHasToken ? (
          <button className={s.headerBtn} type="button" onClick={logOut}>
            Log Out
          </button>
        ) : (
          <Link className={s.headerBtn} to={LOGIN_PATH}>
            Log In
          </Link>
        )}
      </div>
    </header>
  );
};

export default Header;
