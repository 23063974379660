import store from "../store";
import { authorization, saveUserInfo, setLoginError } from "../store/actions/user";
import { getRequest, postRequest, putRequest } from "./base";
import { TEST_RESULTS_PATH } from "../routes/constants";
import history from "../history";
import { logsInStarted, logsInFinished } from "../store/actions/loaders";

export class UserProfileAPI {
  static async registration(data) {
    return await postRequest({ url: "api/userprofiles/registration/", data });
  }

  static async logIn({ email, password }) {
    store.dispatch(logsInStarted());
    try {
      const response = await postRequest({
        url: "/api/userprofiles/login/",
        data: { email, password },
      });
      store.dispatch(authorization(response.key));
      localStorage.setItem("token", response.key);
      store.dispatch(logsInFinished());
      history.push(TEST_RESULTS_PATH);
      document.cookie = "login=true";
    } catch (error) {
      store.dispatch(logsInFinished());
      store.dispatch(setLoginError(error?.data?.non_field_errors?.[0]));
    }
  }

  static async logOut(token) {
    document.cookie = "first_load=false";
    const response = await postRequest({
      url: "/api/userprofiles/logout/",
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    document.cookie = "login=false";
    return response;
  }

  static async reset({ email }) {
    return await postRequest({
      url: "/api/userprofiles/password/reset/",
      data: { email },
    });
  }

  static async changePassword({ new_password1, new_password2, uid, token }) {
    const response = await postRequest({
      url: "/api/userprofiles/password/reset/confirm/",
      data: { new_password1, new_password2, uid, token },
    });
    return response;
  }

  static async changeAccountPassword({
    new_password1,
    new_password2,
    old_password,
  }) {
    const response = await postRequest({
      url: "/api/userprofiles/password/change/",
      data: { new_password1, new_password2, old_password },
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    });
    return response;
  }

  static async getUserInfo() {
    const response = await getRequest({
      url: "/api/userprofiles/userprofile/",
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    });
    store.dispatch(saveUserInfo(response));
    return response;
  }

  static async updateUserInfo(id, data) {
    const response = await putRequest({
      url: `/api/userprofiles/userprofile/${id}/`,
      data,
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    });
    store.dispatch(saveUserInfo(response));
    return response;
  }
}
