/**
 * Changes date format
 * @example
 * prettyDate(5, 10); // returns 2
 * @example
 * // returns 3
 * globalNS.method(5, 15);
 * @returns {String} Returns the same date in different format
 */
const defaultDateSettings = [{day: 'numeric'}, {month: '2-digit'}, {year: 'numeric'}];
export function prettyDate (date, settings = defaultDateSettings, splitter = "/")  {
    if (!date) {
        return null
    };

    function format(setting) {
        let dateTimeFormat = new Intl.DateTimeFormat('en', setting);
        return dateTimeFormat.format(new Date(date));
    }

    return settings.map(format).join(splitter);
}