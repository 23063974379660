import React from "react";
import "./CardExpiryElement.scss";

import {CardExpiryElement} from '@stripe/react-stripe-js';

const DEFAULT_EXPIRY_OPTIONS = {
    style: {
        base: {
            fontFamily: "Roboto",
            fontSize: "16px",
            fontWeight: 300,
            fontSmoothing: 'antialiased',
            lineHeight: '56px',
            ':-webkit-autofill': {
                color: '#ff6702'
            },
            '::placeholder': {
                color: '#000000'
            },
        },
        invalid: {
            iconColor: '#ffc7ee',
            color: '#ffc7ee',
        },
    },
  };



const StripeExpiryDate = ({onChange, errorMessage = "", stripe}) => {
    return (
        <div className={"formGroup"} id="cardExpiryDate">
            <label>
                Card Expiry Date
            </label>
            {/* <Elements stripe={stripe}> */}
                <CardExpiryElement 
                    className={"Stripe_CVC"}
                    options={DEFAULT_EXPIRY_OPTIONS}
                    onChange={onChange}
                />
            {/* </Elements> */}
            {
                errorMessage && 
                <p className={"inputErrorMessage"}>
                    {errorMessage}
                </p>    
            }
        </div>
        
    )

}

export default StripeExpiryDate;