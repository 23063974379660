import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Switch, Route, Link, Redirect } from "react-router-dom";

import { Logo, Footer, Loader } from "../../components";
import LoginForm from "../../forms/login";
import PasswordRecoveryForm from "../../forms/password-recovery-form";
import { CHECK_EMAIL_PATH, LOGIN_PATH } from "../../routes/constants";
import s from "./Login.module.scss";
import { ReactComponent as EmailIcon } from "../../assets/svg/email.svg";

const Login = () => {
  const [isUserHasToken] = useState(
    localStorage.getItem("token")
  );

  const { logsInLoading } = useSelector(({ loaders }) => loaders);
  return (
    <>
      {logsInLoading && Loader.Large}
      <div className={s.login}>
        <div className={s.loginHeader}>
          <Logo />
        </div>

        <div className={s.content}>
          <Switch>
            {isUserHasToken && <Redirect to="/test-results" />}
            <Route path={LOGIN_PATH} exact>
              <h2>Login</h2>
              <LoginForm />
            </Route>
            <Route path="/login/password-recovery">
              <h2 data-style="recover">Recover password</h2>
              <p>
                Please, write your email address to receive a message with
                instructions for recovering your password
              </p>
              <PasswordRecoveryForm />
            </Route>
            <Route path={CHECK_EMAIL_PATH}>
              <div className={s.emailIcon}>
                <EmailIcon />
              </div>
              <h2>Check your email inbox</h2>
              <p>
                If we have a matching email address in our database, you'll have
                an email in your inbox with a link to set a new password
              </p>

              <Link className="btn" to={LOGIN_PATH}>
                Go to login
              </Link>
            </Route>
          </Switch>
        </div>
      </div>
      <Footer dataStyle={"withoutEllipse"} />
    </>
  );
};

export default Login;
