import React from "react";
import { ReactComponent as Loader } from "../../assets/svg/loader.svg";
import * as styles from "./styles.module.scss"

const small = "small";
const large = "large";

const LoaderContainer = (size, ...props) => {
  return (
    <>
      <div className={`${styles[size]}`}>
        <div className={styles.loaderImage}>
          <Loader />
        </div>
      </div>
      {size === large && <div className={styles.modalOverlay} />}
    </>
  )
}

/* eslint-disable  import/no-anonymous-default-export */
export default {
  Large: LoaderContainer(large),
  Small: LoaderContainer(small)
};
/* eslint-enable  import/no-anonymous-default-export */