import {
    LOG_OUT,
    SAVE_TESTS_INFO,
    UPDATE_TEST_TYPE,
    SAVE_USER_TESTS
} from "../../constants";

const initialState = {
    oneTimePayedTest: null,
    activeTestType: "subscription",
    userTests: []
};

const testReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case SAVE_TESTS_INFO:
            return {
                ...state,
                oneTimePayedTest: payload
            };
        case SAVE_USER_TESTS:
            return {
                ...state,
                userTests: payload
            };
        case UPDATE_TEST_TYPE:
            return {
                ...state,
                activeTestType: payload
            }
        case LOG_OUT:
            return initialState;
        default:
            return state;
    }
}

export default testReducer;