import React from "react";
import { Link } from "react-router-dom";

import s from "./OnItsWay.module.scss";

// components
import Layout from "../../components/Layout";
import Steps from "../../components/Steps";
import Banner from "../../components/Banner";
import PageHeading from "../../components/PageHeading";

const TestOnWay = () => {
  return (
    <Layout>
      <PageHeading dataStyle='TestOnWay'>
        <h2>Your test is on its way!</h2>
      </PageHeading>

      <div className={s.TestOnWay}>
        <div className="container">
          <h4 className={s.TestOnWaySubTitle}>Next steps</h4>

          <Steps withoutSignUp />

          <Banner />

          <Link className="btn" to="/">
            Home
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default TestOnWay;
