import { UPDATE_LOADING_STATUS } from "../constants";

export const getUserTestsStarted = () => ({
  type: UPDATE_LOADING_STATUS,
  payload: {
    name: "userTestsLoading",
    status: true
  }
});

export const getUserTestsFinished = () => ({
  type: UPDATE_LOADING_STATUS,
  payload: {
    name: "userTestsLoading",
    status: false
  }
});

export const getUserSubscriptionStarted = () => ({
  type: UPDATE_LOADING_STATUS,
  payload: {
    name: "userSubcriptionLoading",
    status: true
  }
})

export const getUserSubscriptionFinished = () => ({
  type: UPDATE_LOADING_STATUS,
  payload: {
    name: "userSubcriptionLoading",
    status: false
  }
})

export const logsInStarted = () => ({
  type: UPDATE_LOADING_STATUS,
  payload: {
    name: "logsInLoading",
    status: true
  }
})

export const logsInFinished = () => ({
  type: UPDATE_LOADING_STATUS,
  payload: {
    name: "logsInLoading",
    status: false
  }
})