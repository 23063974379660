import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import s from "../get-test-form/GetTestForm.module.scss";

// components
import FormField from "../../components/FormField";
import { UserProfileAPI } from "../../api/userProfile";
import { useHistory } from "react-router-dom";
import FormikErrorFocus from "formik-error-focus";
import { Loader } from "../../components";

const validationSchema = Yup.object({
  address_line_first: Yup.string()
    .required("Required")
    .matches(
      /^[A-Za-z0-9][A-Za-z0-9 ]*[A-Za-z0-9]*$/,
      "Only letters and numbers are allowed"
    )
    .max(50, "Maximum 50 letters."),
  address_line_second: Yup.string()
    .matches(
      /^[A-Za-z0-9][A-Za-z0-9 ]*[A-Za-z0-9]*$/,
      "Only letters and numbers are allowed"
    )
    .max(50, "Maximum 50 letters."),
  city: Yup.string()
    .required("Required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
    .max(100, "Maximum 100 letters."),
  country: Yup.string()
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
    .max(50, "Maximum 50 letters."),
  zip_code: Yup.string()
    .required("Required")
    .matches(
      /^[A-Za-z0-9][A-Za-z0-9 ]*[A-Za-z0-9]*$/,
      "Only letters and numbers are allowed"
    )
    .max(50, "Maximum 50 letters."),
});

const GetTestFormWithAuth = () => {
  const history = useHistory();
  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    UserProfileAPI.getUserInfo().then((res) => {
      setUserInfo({ ...res });
    });
  }, []);
  const onSubmit = async (requestBody, id) => {
    const data = {
      user: {
        email: requestBody.email,
        first_name: requestBody.first_name,
        last_name: requestBody.last_name,
      },
      phone_number: requestBody.phone_number,
      parent_user_token: localStorage.getItem("token"),
      billing_info: {
        address_line_first: requestBody.address_line_first,
        address_line_second: requestBody.address_line_second,
        city: requestBody.city,
        country: requestBody.country,
        zip_code: requestBody.zip_code,
      },
    };
    await UserProfileAPI.updateUserInfo(id, data);
    history.push("/get-test/confirm/");
  };
  if (userInfo) {
    return (
      <div className={s.GetTest}>
        <Formik
          initialValues={{
            address_line_first:
              userInfo?.billing_info?.address_line_first || "",
            address_line_second:
              userInfo?.billing_info?.address_line_second || "",
            city: userInfo?.billing_info?.city || "",
            country: userInfo?.billing_info?.country || "",
            zip_code: userInfo?.billing_info?.zip_code || "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values, userInfo.id);
            setSubmitting(false);
          }}
        >
          <Form className={s.getTestForm}>
            <div className={s.billingAddress} data-style="authorized">
              <div className="container">
                <h4>Billing Address</h4>

                <FormField
                  label="Address line 1"
                  name="address_line_first"
                  type="text"
                  required
                />
                <FormField
                  label="Address line 2"
                  name="address_line_second"
                  type="text"
                  required
                />
                <FormField label="City" name="city" type="text" required />
                <FormField label="Country" name="country" type="text" />
                <FormField
                  label="Postcode"
                  name="zip_code"
                  type="text"
                  required
                />

                <button className="btn btn-orange" type="submit">
                  Submit
                </button>
                <FormikErrorFocus
                  offset={-35}
                  align={"top"}
                  focusDelay={200}
                  ease={"linear"}
                  duration={500}
                />
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    );
  } else {
    return <div>{Loader.Small}</div>;
  }
};

export default GetTestFormWithAuth;
