import store from '../store';
import { postRequest, getRequest, deleteRequest } from './base';
import { saveUserCard, saveUserStripeSubscription } from "../store/actions/user";
export class PaymentsAPI {
    static async updateDefaultCard(cardToken) {
        const response = await postRequest({
            url: "/api/payments/update-default-card/",
            data: { card_token: cardToken },
            headers: {
                Authorization: `Token ${localStorage.getItem("token")}`
            }
        })
        store.dispatch(saveUserCard({...response}))
        return response;
    }

    static async paymentForTest(id) {
        const response = await postRequest({
            url: "/api/payments/payment-for-test/",
            data: { test_id: id },
            headers: {
                Authorization: `Token ${localStorage.getItem("token")}`
            }
        });
        return response;
    }

    static async paymentSubscription(data) {
        const response = await postRequest({
            url: "/api/payments/stripe-subscriptions/",
            data,
            headers: {
                Authorization: `Token ${localStorage.getItem("token")}`
            }
        })

        return response;
    }

    static async getUserSubscription() {
        const response = await getRequest({
            url: "/api/payments/stripe-subscriptions/",
            headers: {
                Authorization: `Token ${localStorage.getItem("token")}`
            }
        })
        store.dispatch(saveUserStripeSubscription(response.results?.[0]))
        return response;
    }

    static async paymentHistory() {
        const response = await getRequest({
            url: "/api/payments/payment-history/",
            headers: {
                Authorization: `Token ${localStorage.getItem("token")}`
            }
        })
        return response;
    }

    static async getPaymentCard() {
        const response = await getRequest({
            url: "/api/payments/cards/",
            headers: {
                Authorization: `Token ${localStorage.getItem("token")}`
            }
        })
        if (response.results.length > 0) {
            store.dispatch(saveUserCard({...response.results?.[0]}));
        }
        return response;
    }

    static async deleteUserSubscription(id) {
        const response = await deleteRequest({
            url: `/api/payments/stripe-subscriptions/${id}/`,
            headers: {
                Authorization: `Token ${localStorage.getItem("token")}`
            }
        });
        return response;
    }
}