import s from "./ArticlesSlider.module.scss";
import React from "react";
import useMedia from "../../../hooks/useMedia";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";
import Arrows from "../Arrows";

const settings = {
    className: "center",
    centerMode: true,
    centerPadding: "15px",
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 1,
    nextArrow: <Arrows.Next dataStyle={'article'} />,
    prevArrow: <Arrows.Prev dataStyle={'article'} />,
    responsive: [
        {
            breakpoint: 1150,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                dots: false,
                initialSlide: 2
            }
        },
    ]
};

const ArticlesSlider = () => {
    let media = useMedia("(max-width: 1150px) ")

    if (media) {
        return (
            <div className={s.trustedWorldwideBigNumbers}>
                <Slider {...settings}>
                    <div className={s.cardWrapper}>
                        <div className={s.testimonialsCard}>
                            <p>
                                Lorem ipsum dolor sit amet, cons ectetur adipiscing elit. Nullam
                                blandit neque nec diam ullamcorper, sit amet porta arcu lacinia.
                                Sed non tortor et est semper iaculis quis ac enim. Aenean nec
                                sodales leo, sit amet consequat sem. Donec cons ectetur augue
                                eget.
                            </p>

                            <h4>Sally Jones</h4>
                            <span>Medical Officer, Surrey</span>
                        </div>
                    </div>
                    <div className={s.cardWrapper}>
                        <div className={s.testimonialsCard}>
                            <p>
                                Lorem ipsum dolor sit amet, cons ectetur adipiscing elit. Nullam
                                blandit neque nec diam ullamcorper, sit amet porta arcu lacinia.
                                Sed non tortor et est semper iaculis quis ac enim. Aenean nec
                                sodales leo, sit amet consequat sem. Donec cons ectetur augue
                                eget.
                            </p>

                            <h4>Sally Jones</h4>
                            <span>Medical Officer, Surrey</span>
                        </div>
                    </div>
                    <div className={s.cardWrapper}>
                        <div className={s.testimonialsCard}>
                            <p>
                                Lorem ipsum dolor sit amet, cons ectetur adipiscing elit. Nullam
                                blandit neque nec diam ullamcorper, sit amet porta arcu lacinia.
                                Sed non tortor et est semper iaculis quis ac enim. Aenean nec
                                sodales leo, sit amet consequat sem. Donec cons ectetur augue
                                eget.
                            </p>

                            <h4>Sally Jones</h4>
                            <span>Medical Officer, Surrey</span>
                        </div>
                    </div>
                </Slider>
            </div>
        )
    }
    return (
        <div className={s.testimonialsCards}>
            <div className={s.testimonialsCard}>
                <p>
                    Lorem ipsum dolor sit amet, cons ectetur adipiscing elit. Nullam
                    blandit neque nec diam ullamcorper, sit amet porta arcu lacinia.
                    Sed non tortor et est semper iaculis quis ac enim. Aenean nec
                    sodales leo, sit amet consequat sem. Donec cons ectetur augue
                    eget.
                </p>

                <h4>Sally Jones</h4>
                <span>Medical Officer, Surrey</span>
            </div>
            <div className={s.testimonialsCard}>
                <p>
                    Lorem ipsum dolor sit amet, cons ectetur adipiscing elit. Nullam
                    blandit neque nec diam ullamcorper, sit amet porta arcu lacinia.
                    Sed non tortor et est semper iaculis quis ac enim. Aenean nec
                    sodales leo, sit amet consequat sem. Donec cons ectetur augue
                    eget.
                </p>

                <h4>Sally Jones</h4>
                <span>Medical Officer, Surrey</span>
            </div>
            <div className={s.testimonialsCard}>
                <p>
                    Lorem ipsum dolor sit amet, cons ectetur adipiscing elit. Nullam
                    blandit neque nec diam ullamcorper, sit amet porta arcu lacinia.
                    Sed non tortor et est semper iaculis quis ac enim. Aenean nec
                    sodales leo, sit amet consequat sem. Donec cons ectetur augue
                    eget.
                </p>

                <h4>Sally Jones</h4>
                <span>Medical Officer, Surrey</span>
            </div>
        </div>
    )
}

export default ArticlesSlider